<div class="page-container">
  <div class="header"></div>
  <div class="language">
    <span [ngClass]="{ selected: isLanguageSelected('de') }" (click)="selectLanguage('de')">DE</span>
    <span>/</span>
    <span [ngClass]="{ selected: isLanguageSelected('en') }" (click)="selectLanguage('en')">EN</span>
  </div>
  <div class="page-content">
    <router-outlet></router-outlet>
  </div>
  <div class="claim"></div>
  <div class="footer">
    <a href="https://www.xrstager.com/" target="_blank" rel="noreferrer">{{ 'links.xrstager' | translate }}</a>
    <a href="https://www.xrstager.com/kontakt" target="_blank" rel="noreferrer">{{ 'links.contact' | translate }}</a>
    <a href="https://www.xrstager.com/datenschutz" target="_blank" rel="noreferrer">{{
      'links.privacy' | translate
    }}</a>
    <a href="https://www.xrstager.com/impressum" target="_blank" rel="noreferrer">{{ 'links.legal' | translate }}</a>
  </div>
  <div class="version-info">v{{ version }}</div>
</div>
