<div class="modal-header">
  <h4 class="modal-title">{{ 'modal.invite.title' | translate }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <p>{{ 'modal.invite.description' | translate }}</p>
  <div class="d-flex flex-direction-row justify-content-center">
    <div class="btn btn-primary" (click)="copyLink()">{{ 'action.copy_invitation_link' | translate }}</div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">
    {{ 'action.close' | translate }}
  </button>
</div>
