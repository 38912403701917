export enum ApplicationStatus {
  Offline = 'offline',
  Online = 'online',
  Occupied = 'occupied',
}

export interface XrsApplication {
  id: string;
  name: string;
  signalingServer: string | undefined;
  state: ApplicationStatus;
  lastUpdate: number;
}
