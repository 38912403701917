import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { XrsApplication } from '../model/xrs-application';
import { Socket } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class XrsApplicationService {
  constructor(private socket: Socket, private route: ActivatedRoute) {}

  async getApplicationFromUrl(): Promise<XrsApplication | undefined> {
    const queryParamMap = await firstValueFrom(this.route.queryParamMap);
    if (queryParamMap && queryParamMap.has('applicationId')) {
      const applicationId = queryParamMap.get('applicationId') ?? '';
      return await this.getApplication(applicationId);
    }
    return;
  }

  async getApplications(): Promise<XrsApplication[]> {
    this.socket.emit('getApplications');
    return await this.socket.fromOneTimeEvent<XrsApplication[]>('applications');
  }

  async getApplication(id: string): Promise<XrsApplication | undefined> {
    const applications = await this.getApplications();
    return applications.find((application) => application.id === id);
  }

  /**
   * Return the signaling server url that was defined in the unity application.
   * When the environment file specifies a signaling server url for the application, this url is instead returned.
   */
  getSignalingServer(application: XrsApplication | undefined): string | undefined {
    const hasEnvSignalingServer = !!(environment as any)?.signalingServerUrl;
    const envSignalingServer = hasEnvSignalingServer
      ? (environment as any)?.signalingServerUrl[application?.id || '']
      : undefined;
    return envSignalingServer ?? application?.signalingServer ?? undefined;
  }
}
