import { Injectable } from '@angular/core';
import { Subject, Subscription, interval } from 'rxjs';

/**
 * This service is used to detect if the input channel is alive by if a send
 * input message is acknowledged by unity.
 */
@Injectable({
  providedIn: 'root',
})
export class InputChannelGuardService {
  onInputChannelTimeout = new Subject();
  subscription: Subscription;

  private lastSendInputTimestamp: number;
  private lastReceivedInputTimestamp: number;

  start() {
    this.reset();

    this.subscription = interval(1000).subscribe(() => {
      this.checkTimeout();
    });
  }

  stop() {
    this.subscription?.unsubscribe();
  }

  reset() {
    this.lastSendInputTimestamp = 0;
    this.lastReceivedInputTimestamp = 0;
  }

  /**
   * Call this whenever the input channel send some data
   */
  updateSend(): void {
    this.lastSendInputTimestamp = Date.now();
  }

  /**
   * Call this whenever the input channel received some data
   */
  updateReceived(): void {
    this.lastReceivedInputTimestamp = Date.now();
  }

  private checkTimeout(): void {
    if (this.lastSendInputTimestamp === 0 || this.lastReceivedInputTimestamp === 0) {
      return;
    }
    if (this.lastSendInputTimestamp - this.lastReceivedInputTimestamp > 3000) {
      this.reset();
      this.onInputChannelTimeout.next({});
    }
  }
}
