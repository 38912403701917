<div class="modal-header">
  <h4 class="modal-title">Stream: {{ application.name }}</h4>
  <button type="button" class="btn-close" (click)="activeModal.dismiss('dismiss')"></button>
</div>
<div class="modal-body">
  <div class="stream_settings">
    <div class="stream_settings__line">
      <div class="stream_settings__label">{{ 'modal.stream_admin_panel.reset_stream' | translate }}</div>
      <button class="btn btn-outline-danger btn-sm" (click)="resetStream()">{{ 'action.reset' | translate }}</button>
    </div>
    <ul *ngIf="sessionUsers.length > 0">
      <li *ngFor="let user of sessionUsers; let i = index">
        {{ user.name }} <span *ngIf="i === 0">{{ 'modal.stream_admin_panel.host' | translate }}</span>
      </li>
    </ul>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">
    {{ 'action.back' | translate }}
  </button>
</div>
