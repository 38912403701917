import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  title: 'XR Stager',
  production: false,
  logLevel: NgxLoggerLevel.DEBUG,

  keycloak: {
    client: 'xrstager',
    issuer: 'https://auth.xrstager.online/realms/xrstager',
    url_token: 'https://auth.xrstager.online/realms/xrstager/protocol/openid-connect/token',
    url_login: 'https://auth.xrstager.online/realms/xrstager/protocol/openid-connect/auth',
    url_logout: 'https://auth.xrstager.online/realms/xrstager/protocol/openid-connect/logout',
    url_userInfo: 'https://auth.xrstager.online/realms/xrstager/protocol/openid-connect/userinfo',
    url_revocation: 'https://auth.xrstager.online/realms/xrstager/protocol/openid-connect/revoke',
    client_id: 'xrstager',
  },
  remotely: {
    base_url: 'https://remote.xrstager.online',
    organization: '68cf2a10-24a5-4cb3-a480-cbb38966bd86',
    secret:
      'Basic ZDFlNDM4YTUtM2FlZC00ZjViLTg4NDYtNDE0ZjM1NzdmMjJmOmxwZXhoSXNDZWtCOW1NNU83aDczUkE2eURINEdyVC9CRUVYSkJwa3c2YWNUd0VXVw==',
    refreshInterval: 5000,
    linkExpirationInSeconds: 60,
    endpoint: {
      devices: '/api/Devices',
      remote: '/api/RemoteControl',
    },
  },
  streaming: {
    retryInterval: 1000,
    remotePeerCleanupInterval: 1000,
    remotePeerMaxAge: 10000,
    xrsUserIdleTime: 10000,
    iceServers: [
      // Test #4
      {
        urls: 'stun:fr-turn1.xirsys.com',
      },
      {
        urls: 'turn:fr-turn1.xirsys.com:80?transport=tcp',
        username:
          'ywcuMA52w--kZJLaWzKDuVNK3thU_qB-u3ML-3HLPTmQtISdzJE95zu6a8d5lKjCAAAAAGRrB_9pZGVlbmt1bHRpdmllcnVuZw==',
        credential: 'bf77ca4a-f867-11ed-907f-0242ac120004',
      },
      {
        urls: 'turn:fr-turn1.xirsys.com:3478?transport=tcp',
        username:
          'ywcuMA52w--kZJLaWzKDuVNK3thU_qB-u3ML-3HLPTmQtISdzJE95zu6a8d5lKjCAAAAAGRrB_9pZGVlbmt1bHRpdmllcnVuZw==',
        credential: 'bf77ca4a-f867-11ed-907f-0242ac120004',
      },
    ],
    // iceServers: [ // Test #2
    //   {
    //     urls: 'stun:stun.relay.metered.ca:80',
    //   },
    //   {
    //     urls: 'turn:a.relay.metered.ca:80?transport=tcp',
    //     username: '975789fcad9df8ff7854f4ec',
    //     credential: 'a2HRXRXczKtQQ1HX',
    //   },
    //   {
    //     urls: 'turn:a.relay.metered.ca:443?transport=tcp',
    //     username: '975789fcad9df8ff7854f4ec',
    //     credential: 'a2HRXRXczKtQQ1HX',
    //   },
    // ],
    // iceServers: [
    //   // Test #3
    //   {
    //     urls: 'stun:fr-turn1.xirsys.com',
    //   },
    //   {
    //     urls: 'turn:fr-turn1.xirsys.com:80?transport=udp',
    //     username: 'ywcuMA52w--kZJLaWzKDuVNK3thU_qB-u3ML-3HLPTmQtISdzJE95zu6a8d5lKjCAAAAAGRrB_9pZGVlbmt1bHRpdmllcnVuZw==',
    //     credential: 'bf77ca4a-f867-11ed-907f-0242ac120004',
    //   },
    //   {
    //     urls: 'turn:fr-turn1.xirsys.com:3478?transport=udp',
    //     username: 'ywcuMA52w--kZJLaWzKDuVNK3thU_qB-u3ML-3HLPTmQtISdzJE95zu6a8d5lKjCAAAAAGRrB_9pZGVlbmt1bHRpdmllcnVuZw==',
    //     credential: 'bf77ca4a-f867-11ed-907f-0242ac120004',
    //   },
    // ],
    // iceServers: [
    //   // Test #4
    //   {
    //     urls: 'stun:fr-turn1.xirsys.com',
    //   },
    //   {
    //     urls: 'turn:fr-turn1.xirsys.com:80?transport=tcp',
    //     username: 'ywcuMA52w--kZJLaWzKDuVNK3thU_qB-u3ML-3HLPTmQtISdzJE95zu6a8d5lKjCAAAAAGRrB_9pZGVlbmt1bHRpdmllcnVuZw==',
    //     credential: 'bf77ca4a-f867-11ed-907f-0242ac120004',
    //   },
    //   {
    //     urls: 'turn:fr-turn1.xirsys.com:3478?transport=tcp',
    //     username: 'ywcuMA52w--kZJLaWzKDuVNK3thU_qB-u3ML-3HLPTmQtISdzJE95zu6a8d5lKjCAAAAAGRrB_9pZGVlbmt1bHRpdmllcnVuZw==',
    //     credential: 'bf77ca4a-f867-11ed-907f-0242ac120004',
    //   },
    // ],
  },
  signalingServerUrl: {
    // scooter_demo: 'wss://signaling-01.xrstager.online',
    // xrstager_demo: 'wss://signaling-02.xrstager.online',
  },
  backend: {
    url: 'https://backend.xrstager.online',
  },
  debug: {
    signalingService: false,
  },
};
