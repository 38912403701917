import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { interval, Subject, Subscription } from 'rxjs';
import { InputOffer, InputOfferState, ResolvedInputOffer } from '../model/input-types';
import { UserProfile } from '../model/user-profile';
import { SessionService } from './session.service';
import { XrsApplicationService } from './xrs-application.service';
import { Session } from '../model/session';
import { NGXLogger } from 'ngx-logger';

/**
 * This services manages how input can be handled between different viewers of a stream.
 *
 * A host has always priority over other users and has the input when the stream starts. A host can offer the
 * input to other users, which they can accept or decline. During this back and forth a object of type InputOffer
 * is handled by both clients, identified by their user id. There can only be one offer for an application at a time.
 *
 * 1. InputOffer is in state 'HostHasInput' -> Host can offer input to other user
 * 2. After host offered input, the recipient of the offer is notified. They can accept or decline the offer. The state of the offer is 'PendingOffer'
 *    2.1. When the recipient declines the offer is put in an invalid state and can no longer be used.
 *    2.2. If they accept the offer will go into state 'InputOfferAccepted'
 * 3. 'InputOfferAccepted': The host can now disconnect its input from the stream and change the offer to state 'InputAssigned'
 * 4. 'InputAssigned': The recipient can now connect its inputs to the application
 *
 * In every step, the host has the ability to revoke the input, which will invalidate the offer and the host can take over controls again.
 * A recipient can give up its input by 'declining' an offer they accepted.
 */
@Injectable({
  providedIn: 'root',
})
export class InputOfferService {
  /**
   * Received an input offer from the stream owner
   * Relevant for: Stream visitors
   */
  onOfferReceived = new Subject();

  /**
   * An input offer issued by the stream owner was accepted
   * Relevant for: Stream owner
   */
  onOfferAccepted = new Subject();

  onInputAssigned = new Subject();

  /**
   * An input offer issued by the stream owner was declined
   * Relevant for: Stream owner
   */
  onOfferDeclined = new Subject();

  onOfferRevoked = new Subject();

  private inputOfferProcessingSubscription: Subscription;
  private pollIntervall: Subscription;

  private user: UserProfile;
  private session: Session | undefined;
  private currentInputOffer: InputOffer | undefined;
  private previousInputOffer: InputOffer | undefined;
  private previousInputState = InputOfferState.HostHasInput;

  constructor(
    private xrsApplicationService: XrsApplicationService,
    private sessionService: SessionService,
    private socket: Socket,
    private logger: NGXLogger
  ) {}

  public start(applicationId: string, user: UserProfile): void {
    if (!user) {
      this.logger.error(`[InputControlService] start: User is undefined`);
      return;
    }

    this.user = user;

    this.sessionService.getSession(applicationId).subscribe((session) => {
      this.session = session;
    });

    this.inputOfferProcessingSubscription = this.socket
      .fromEvent<InputOffer | undefined>('inputOffer')
      .subscribe((inputOffer) => {
        if (this.offerChanged(inputOffer)) {
          this.processInputOffer(inputOffer);
          this.previousInputOffer = inputOffer;
        }
      });

    //TODO: Polling should not be necessary but this library does not work like i think it would
    this.pollIntervall = interval(1000).subscribe(() => {
      this.socket.emit('getInputOffer', this.session?.applicationId);
    });
  }

  public stop(): void {
    this.session = undefined;
    this.currentInputOffer = undefined;
    this.previousInputState = InputOfferState.HostHasInput;
    this.inputOfferProcessingSubscription?.unsubscribe();
    this.pollIntervall?.unsubscribe();
  }

  private processInputOffer(inputOffer: InputOffer | undefined): void {
    // Accept only offers that are meant for this application and that has this user as the to or from attribute
    const updatedOffer =
      inputOffer && (inputOffer.to === this.user.id || inputOffer.from === this.user.id) ? inputOffer : undefined;

    if (updatedOffer) {
      this.previousInputState = this.currentInputOffer ? this.currentInputOffer.state : InputOfferState.HostHasInput;
      this.currentInputOffer = updatedOffer;

      switch (updatedOffer.state) {
        case InputOfferState.HostHasInput:
          // Do nothing, this is the default case
          break;
        case InputOfferState.PendingOffer:
          if (this.currentInputOffer.to === this.user.id) {
            this.onOfferReceived?.next(undefined);
          }
          break;
        case InputOfferState.AcceptedOffer:
          if (this.currentInputOffer.from === this.user.id) {
            this.onOfferAccepted?.next(undefined);
          }
          break;
        case InputOfferState.InputAssigned:
          if (this.currentInputOffer.to === this.user.id) {
            this.onInputAssigned?.next(undefined);
          }
          break;
        case InputOfferState.DeclinedRevokedOffer:
          this.onOfferDeclined?.next(undefined);
          break;
      }
    }
  }

  /**
   * Offer input control to another stream user. There can only be one input offer at a time
   * and subsequent offers overwrite each other
   */
  public async offerInput(toUserId: string): Promise<void> {
    this.logger.debug(`[InputControlService] offerInput`);
    if (!this.user || !this.sessionService.userIsHost(this.session, this.user)) {
      return;
    }

    this.previousInputState = InputOfferState.PendingOffer;
    this.currentInputOffer = {
      from: this.user.id,
      to: toUserId,
      applicationId: this.session?.applicationId ?? '',
      state: InputOfferState.PendingOffer,
      offerRevoked: false,
      created: Date.now(),
    };

    try {
      await this.socket.emit('addInputOffer', this.currentInputOffer);
    } catch (error) {
      this.logger.error(`[InputControlService] Could not offer input`, error);
      this.previousInputState = InputOfferState.HostHasInput;
      this.currentInputOffer = undefined;
    }
  }

  /**
   * Accept an input offer that was previously received from a host
   */
  public async acceptInputOffer(): Promise<void> {
    this.logger.debug(`[InputControlService] acceptInputOffer`);
    if ((this.user && this.sessionService.userIsHost(this.session, this.user)) || !this.currentInputOffer) {
      return;
    }
    this.updateState(InputOfferState.AcceptedOffer);
  }

  /**
   * Notify the other user that input has been assigned to them
   */
  public async assignInput(): Promise<void> {
    this.logger.debug(`[InputControlService] assignInput`);
    if ((this.user && !this.sessionService.userIsHost(this.session, this.user)) || !this.currentInputOffer) {
      return;
    }
    this.updateState(InputOfferState.InputAssigned);
  }

  /**
   * Decline an input offer that was previously received from a host
   */
  public async declineInputOffer(): Promise<void> {
    this.logger.debug(`[InputControlService] declineInputOffer`);
    if ((this.user && this.sessionService.userIsHost(this.session, this.user)) || !this.currentInputOffer) {
      return;
    }
    this.updateState(InputOfferState.DeclinedRevokedOffer);
    this.onOfferDeclined?.next(undefined);
  }

  /**
   * As a host, revoke your previous input offer and receive input back
   */
  public async revokeInputOffer(): Promise<void> {
    this.logger.debug(`[InputControlService] revokeInputOffer`);
    if ((this.user && !this.sessionService.userIsHost(this.session, this.user)) || !this.currentInputOffer) {
      return;
    }
    this.updateState(InputOfferState.DeclinedRevokedOffer);
    this.onOfferDeclined?.next(undefined);
  }

  public getInputOffer(): InputOffer | undefined {
    return this.currentInputOffer;
  }

  public async getResolvedInputOffer(): Promise<ResolvedInputOffer | undefined> {
    if (!this.currentInputOffer) {
      return;
    }

    if (this.session) {
      const resolvedFromUser = this.session.host;
      const resolvedToUser = this.session.visitors.find((visitor) => visitor.id === this.currentInputOffer?.to);
      const resolvedApplication = await this.xrsApplicationService.getApplication(this.session?.applicationId ?? '');

      if (resolvedFromUser && resolvedToUser) {
        return {
          from: resolvedFromUser,
          to: resolvedToUser,
          application: resolvedApplication,
          state: this.currentInputOffer.state,
          offerRevoked: this.currentInputOffer.offerRevoked,
          created: this.currentInputOffer.created,
        } as ResolvedInputOffer;
      }
    }
    return;
  }

  public visitorHasInput(): boolean {
    return (
      !this.sessionService.userIsHost(this.session, this.user) &&
      this.currentInputOffer?.state === InputOfferState.InputAssigned &&
      this.currentInputOffer.to === this.user.id
    );
  }

  public hasUserInputAssigned(userId: string): boolean {
    if (!this.currentInputOffer) {
      return false;
    }

    return (
      this.currentInputOffer?.state === InputOfferState.InputAssigned &&
      this.currentInputOffer.to === userId &&
      this.currentInputOffer.from !== userId
    );
  }

  private offerChanged(update: InputOffer | undefined): boolean {
    const old = this.previousInputOffer;
    return (
      update?.from !== old?.from ||
      update?.to !== old?.to ||
      update?.applicationId !== old?.applicationId ||
      update?.state !== old?.state ||
      update?.offerRevoked !== old?.offerRevoked
    );
  }

  private async updateState(state: InputOfferState): Promise<void> {
    this.logger.debug(`[InputControlService] Update state to: ${state}`);

    this.previousInputState = state;
    if (this.currentInputOffer) {
      this.currentInputOffer.state = this.previousInputState;
      try {
        this.socket.emit('addInputOffer', this.currentInputOffer);
      } catch (error) {
        this.logger.debug(error);
      }
    }
  }
}
