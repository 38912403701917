import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RemoteStreamService } from 'src/app/services/remote-stream.service';
import { AuthService } from 'src/app/services/auth.service';
import { firstValueFrom, fromEvent, interval, Observable } from 'rxjs';
import { StateService, DisconnectReason, StateTopic } from 'src/app/services/state.service';
import { XRSUser } from 'src/app/model/xrs-user';
import { XrsApplicationService } from 'src/app/services/xrs-application.service';
import { XrsApplication } from 'src/app/model/xrs-application';
import { UserProfile } from 'src/app/model/user-profile';
import { InputOfferService } from 'src/app/services/input-offer.service';
import { InputOfferState, ResolvedInputOffer } from 'src/app/model/input-types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/services/toast.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { SessionService } from 'src/app/services/session.service';
import { Session } from 'src/app/model/session';
import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';
import { NGXLogger } from 'ngx-logger';
import { ConnectionReportService } from 'src/app/services/connection-report.service';
import { TranslateService } from '@ngx-translate/core';

const CONNECTION_LOST_TEXT: string = 'The connection to the host has been lost. Please wait...';
const BAD_CONNECTION_TEXT: string = 'The connection to the host is bad. Please wait...';

@Component({
  selector: 'app-remote-stream',
  templateUrl: './remote-stream.component.html',
  styleUrls: ['./remote-stream.component.scss'],
})
export class RemoteStreamComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('player') videoPlayerRef: ElementRef;
  @ViewChild('offerReceivedTemplate') inputOfferModalTemplate: ElementRef;
  @ViewChild('stillImage') stillImageRef: ElementRef;

  connected = false;
  isFullscreenEnabled = false;
  session: Session | undefined;
  streamUsers: Observable<XRSUser[]>;
  host: XRSUser | undefined;
  userProfile: UserProfile;
  showSoftDisconnectMessage = false;
  disconnectText = 'The stream has not started yet and is waiting for a host. Please wait...';
  userListVisible = false;
  toolbarOpen = true;
  resolvedInputOffer: ResolvedInputOffer | undefined;

  permissionsChecked = false;
  connectionInitialized = false;
  stillImageDataURI: string | null = null;

  private xrsApplication: XrsApplication;
  private offerReceivedModalOpen = false;

  get loadingScreenText(): string {
    const appName = this.xrsApplication?.name;
    if (appName) {
      return `Connecting to ${appName}`;
    } else {
      return '';
    }
  }

  get headerBarText(): string {
    const appName = this.xrsApplication?.name;
    const userName = this.userProfile?.name;

    if (userName && appName) {
      return `${appName} - ${userName}`;
    } else if (appName) {
      return `${appName}`;
    } else {
      return '';
    }
  }

  constructor(
    private router: Router,
    private remoteStreamService: RemoteStreamService,
    private authService: AuthService,
    private xrsApplicationService: XrsApplicationService,
    private stateService: StateService,
    public inputOfferService: InputOfferService,
    private subscriptionService: SubscriptionService,
    private sessionService: SessionService,
    private activatedRoute: ActivatedRoute,
    private connectionReportService: ConnectionReportService,
    private logger: NGXLogger,
    private translateService: TranslateService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.subscriptionService.add(
      'rsc',
      fromEvent(window, 'popstate').subscribe(async (e) => {
        await this.disconnect();
      })
    );

    this.subscriptionService.add(
      'rsc',
      fromEvent(window, 'beforeunload').subscribe(async (e) => {
        await this.disconnect();
      })
    );

    this.subscriptionService.add(
      'rsc',
      fromEvent(window, 'resize').subscribe(async (e) => {
        this.remoteStreamService.resizeVideo();
      })
    );
  }

  async ngOnInit(): Promise<void> {
    this.subscriptionService.add(
      'inputcontrol',
      this.inputOfferService.onOfferReceived.subscribe(() => this.onOfferReceived())
    );

    this.subscriptionService.add(
      'inputcontrol',
      this.inputOfferService.onOfferAccepted.subscribe(() => this.onOfferAccepted())
    );

    this.subscriptionService.add(
      'inputcontrol',
      this.inputOfferService.onInputAssigned.subscribe(() => this.onInputAssigned())
    );

    this.subscriptionService.add(
      'inputcontrol',
      this.inputOfferService.onOfferDeclined.subscribe(() => this.onOfferDeclined())
    );

    this.subscriptionService.add(
      'rsc',
      this.connectionReportService.onConnectionEstablished.subscribe((value) => {
        this.connectionInitialized = true;
      })
    );

    this.subscriptionService.add(
      'connection-tend-to-break',
      this.connectionReportService.onConnectionTendToBreak.subscribe((needToPause) => {
        if (!this.session) {
          return;
        }
        this.remoteStreamService.getVideoPlayer().videoWidth;
        const videoPlayer = this.remoteStreamService.getVideoPlayer();
        if (needToPause && this.connected) {
          videoPlayer.pauseVideo();
        } else {
          videoPlayer.playVideo();
        }
      })
    );
  }

  async ngAfterViewInit(): Promise<void> {
    // Check if there is actually an app associated with the current app id.
    // If not, return to the app panel
    const app = await this.xrsApplicationService.getApplicationFromUrl();
    if (app) {
      this.xrsApplication = app;
    } else {
      await this.handleDisconnect(DisconnectReason.InvalidApplication);
      return;
    }

    this.handleJoinRequest(app);
  }

  async ngOnDestroy(): Promise<void> {
    this.connected = false;
    // this.sessionService.stopUserUpdate();
    this.inputOfferService.stop();
    this.subscriptionService.unsubscribe('guard');
    this.subscriptionService.unsubscribe('inputcontrol');
  }

  /**
   * When a user enters this view we have to decide whether to create a new session, join an existing session or
   * show the waiting room. This is done based on the following rules:
   * - If there is no session running and the user is allowed to be a session host, create a new session
   * - If there is a session running and the user is already in this session, re-join the stream, because this us most likely a page reload
   * - If there is a session running and the user is not in this session, join the session if the user logged in
   * - If there is a session running and the user is not in this session, show the waiting room if the user is not logged in
   */
  private async handleJoinRequest(app: XrsApplication): Promise<void> {
    const session = await this.sessionService.getSessionOnce(app.id);
    const user = await this.authService.getUserProfile(true);

    if (!session && user && this.checkUserCanBeHost(user)) {
      /*
       * Create a new session when there is no running session and the user has the rights to be host of this session
       */
      this.permissionsChecked = true;
      this.userProfile = user;
      this.createSession(user);
    } else if (session && user && this.sessionService.userIsInSession(session, user)) {
      /*
       * When a session exists and the current user already joined this session, this can mean the following:
       * - The user did reload the page
       * - A user changed from being in the waiting room to the stream
       */
      this.permissionsChecked = true;

      // Get the user that is already in the session
      // If the browser ids are different, than a logged in user tries to join the session from another browser or device.
      // To prevent errors, the user is redirected to the waiting room as a guest user.
      // If the browser ids are the same, the user is already in the session and can join the stream
      const userInSession = this.sessionService.getSessionUser(session, user);
      if (userInSession?.browserId !== this.authService.uniqueBrowserId) {
        this.handleSoftDisconnect(DisconnectReason.UserAlreadyInSession);
      } else {
        this.joinSession(session, user);
      }
    } else if (session) {
      /*
       * If the stream allows visitory then add them to the waiting room
       * Otherwise reroute them to the start page
       */
      if (session.allowVisitors) {
        this.permissionsChecked = true;
        this.joinWaitingRoom();
      } else {
        await this.handleDisconnect(DisconnectReason.InvalidApplication);
      }
    } else {
      await this.handleDisconnect(DisconnectReason.InvalidApplication);
    }
  }

  /**
   * Create a new session, start session updates and setup the streaming
   */
  private async createSession(userProfile: UserProfile): Promise<void> {
    this.logger.info('[RemoteStreamComponent] Creating new session...');
    const session = this.sessionService.createSession(this.xrsApplication.id, userProfile);

    if (!session) {
      this.handleDisconnect(DisconnectReason.InvalidApplication);
      return;
    }

    this.host = session.host;
    this.session = session;

    this.receiveSessionUpdates();
    this.sessionService.startSessionUpdate(this.session);
    this.inputOfferService.start(this.session.applicationId, this.userProfile);
    this.setupStreaming();
  }

  /**
   * Join an existing session, start session updates if this user is the host of the session
   * @param session
   * @param user
   * @returns
   */
  private async joinSession(session: Session, user: UserProfile): Promise<void> {
    this.logger.info('[RemoteStreamComponent] Join existing session...');

    if (!session) {
      this.handleDisconnect(DisconnectReason.InvalidApplication);
      return;
    }
    this.session = session;
    this.host = session.host;
    this.userProfile = user;

    this.receiveSessionUpdates();

    if (this.sessionService.userIsHost(this.session, this.userProfile)) {
      this.sessionService.startSessionUpdate(this.session);
    }

    this.inputOfferService.start(this.session.applicationId, this.userProfile);
    this.setupStreaming();
  }

  /**
   * Join the waiting room if there is a session running and the user is not logged in.
   * In this case an anonymous user is created and the user is redirected to the waiting room.
   */
  private async joinWaitingRoom(): Promise<void> {
    this.logger.info('[RemoteStreamComponent] Join waiting room...');

    this.userProfile = this.authService.getOrCreateAnonymousUserProfile();
    const queryParamMap = await firstValueFrom(this.activatedRoute.queryParamMap);

    const connectionId = queryParamMap.get('connectionId') ?? '';
    const applicationId = queryParamMap.get('applicationId') ?? '';
    const userId = this.userProfile.id;

    await this.router.navigate([''], {
      queryParams: {
        page: 'waiting-room',
        connectionId,
        applicationId,
        userId,
      },
    });
  }

  private checkUserCanBeHost(user: UserProfile | undefined): boolean {
    return !!user && user?.canControlStream;
  }

  private checkUserAlreadyJoined(session: Session, user: UserProfile | undefined): boolean {
    const isVisitor = !!session?.visitors.find((visitor) => visitor.id === user?.id) || false;
    const isHost = session !== undefined && session?.host.id === user?.id;
    return isVisitor || isHost;
  }

  private setupStreaming() {
    this.showSoftDisconnectMessage = false;

    this.subscriptionService.add(
      'remoteservice',
      this.remoteStreamService.onConnect.subscribe(() => this.onConnect())
    );
    this.subscriptionService.add(
      'remoteservice',
      this.remoteStreamService.onDisconnect.subscribe(() => this.onDisconnect())
    );
    this.subscriptionService.add(
      'remoteservice',
      this.remoteStreamService.onTrackEnded.subscribe(() => this.handleTrackEnded())
    );
    this.subscriptionService.add('remoteservice', this.remoteStreamService.onGotOffer.subscribe());

    if (!this.connectionInitialized) {
      this.remoteStreamService.start(this.videoPlayerRef?.nativeElement, this.xrsApplication, this.userProfile);
    }
  }

  private async onConnect(): Promise<void> {
    if (!this.session) {
      this.logger.error('[RemoteStreamComponent] onConnect: Session is undefined');
      return;
    }
    this.stillImageDataURI = null;

    this.sessionService.startUserUpdate(this.session, this.userProfile);
    /*
     * When a guest user is in stream that no longer has a host, they should be disconnected
     */
    this.initStreamHasNoHostGuard();

    this.initStreamApplicationNotAvailableGuard();

    /*
     * When a user was removed from the backend database they should be disconnected from this stream
     */
    this.initUserWasRemovedGuard();

    setTimeout(() => {
      this.assignInputIfNecessary();
    }, 1000);

    this.connected = true;
  }

  private async onDisconnect(): Promise<void> {
    this.logger.debug('[RemoteStreamComponent] onDisconnect');
    window.location.reload();

    // const videoPlayer = this.remoteStreamService.getVideoPlayer();
    // this.stillImageDataURI = videoPlayer.captureImage();
    // await this.tryReconnect();
  }

  async tryReconnect(): Promise<void> {
    this.logger.debug('[RemoteStreamComponent] tryReconnect');
    this.subscriptionService.unsubscribe('remoteservice');

    return new Promise(async (resolve) => {
      this.connected = false;
      this.remoteStreamService.stop();

      setTimeout(() => {
        this.setupStreaming();
        resolve();
      }, 1000);
    });
  }

  async onHome(): Promise<void> {
    await this.disconnect();

    const userProfile = await this.authService.getUserProfile();

    // If user used a direct application connection, directly log them out
    if (!!userProfile?.directApplicationConnection) {
      await this.authService.logout();
      await this.router.navigate([''], { queryParams: { page: 'login' } });
    } else {
      await this.router.navigate([''], { queryParams: { page: 'devices' } });
    }
  }

  async disconnect(): Promise<void> {
    this.logger.debug('[RemoteStreamComponent] disconnect');
    this.subscriptionService.unsubscribe('remoteservice');
    this.subscriptionService.unsubscribe('rsc');
    this.subscriptionService.unsubscribe('session');
    this.subscriptionService.unsubscribe('guard');

    this.sessionService.stopUserUpdate();
    this.sessionService.stopSessionUpdate();
    if (this.sessionService.userIsHost(this.session, this.userProfile)) {
      this.sessionService.deleteSession(this.session);
    }

    return new Promise(async (resolve) => {
      this.connected = false;
      this.remoteStreamService.stop();

      setTimeout(() => {
        resolve();
      }, 1000);
    });
  }

  /**
   * Disconnect from stream and immediately return to the device panel
   */
  private async handleDisconnect(reason: DisconnectReason, message: string = ''): Promise<void> {
    this.logger.warn(`[RemoteStreamComponent] handleDisconnect: reason:${DisconnectReason[reason]}`);
    await this.disconnect();
    this.stateService.add({ topic: StateTopic.Error, reason, message });
    await this.router.navigate([''], {
      queryParams: { page: 'login' },
    });
  }

  /**
   * Disconnect from stream and show an error message why the connection was disconnected
   */
  private async handleSoftDisconnect(reason: DisconnectReason): Promise<void> {
    this.logger.warn(`[RemoteStreamComponent] handleSoftDisconnect: reason:${DisconnectReason[reason]}`);
    await this.disconnect();
    this.showSoftDisconnectMessage = true;
    let logout = false;
    switch (reason) {
      case DisconnectReason.AnonymousStreamHasNoOwner:
        this.disconnectText = this.translateService.instant('disconnect-reason.no_stream_host');
        break;
      case DisconnectReason.RemovedFromStream:
        this.disconnectText = this.translateService.instant('disconnect-reason.removed_from_stream');
        break;
      case DisconnectReason.InvalidApplication:
        this.disconnectText = this.translateService.instant('disconnect-reason.invalid_application');
        break;
      case DisconnectReason.UserAlreadyInSession:
        logout = true;
        this.disconnectText = this.translateService.instant('disconnect-reason.user_already_joined');
        break;
      default:
        this.disconnectText = '';
        break;
    }
    setTimeout(() => {
      if (logout) {
        this.authService.logout();
      }
      this.navigateToLogin();
    }, 5000);
  }

  private async navigateToLogin(): Promise<void> {
    await this.router.navigate([''], {
      queryParams: { page: 'login' },
    });
  }

  private async handleTrackEnded(): Promise<void> {
    this.logger.debug('[RemoteStreamComponent] Track ended before it even started. Try again.');
    await this.disconnect();
    this.setupStreaming();
  }

  private receiveSessionUpdates(): void {
    this.subscriptionService.add(
      'session',
      this.sessionService.getSession(this.xrsApplication.id).subscribe((session) => {
        this.session = session;
      })
    );
  }

  private initStreamHasNoHostGuard(): void {
    const sub = interval(1000).subscribe(() => {
      if (!this.sessionService.streamHasHost(this.session)) {
        this.handleSoftDisconnect(DisconnectReason.AnonymousStreamHasNoOwner);
      }
    });
    this.subscriptionService.add('guard', sub);
  }

  /**
   * Initialize a guard that checks if the application is still available.
   * This can happen when the sender is closed or the network connection is lost.
   */
  private initStreamApplicationNotAvailableGuard(): void {
    const sub = interval(1000).subscribe(async () => {
      const app = await this.xrsApplicationService.getApplication(this.xrsApplication.id);
      if (!app || app.state === 'offline') {
        this.handleSoftDisconnect(DisconnectReason.InvalidApplication);
      }
    });
    this.subscriptionService.add('guard', sub);
  }

  /**
   * Initialize a guard that checks if the user is still in the session.
   * This can happen if a host removed the user or an admin removed the user.
   */
  private initUserWasRemovedGuard(): void {
    const sub = interval(1000).subscribe(() => {
      if (!this.sessionService.userIsInSession(this.session, this.userProfile)) {
        this.handleSoftDisconnect(DisconnectReason.RemovedFromStream);
      }
    });
    this.subscriptionService.add('guard', sub);
  }

  /**
   * When reconnecting or reloading the stream it can happen that this user has the input assigned.
   * Restore the input, if this is the case.
   */
  private assignInputIfNecessary(): void {
    const inputOffer = this.inputOfferService.getInputOffer();
    const userHasInputAssigned =
      inputOffer && inputOffer.state === InputOfferState.InputAssigned && this.userProfile.id === inputOffer?.to;

    if (userHasInputAssigned) {
      const overrideHost = true;
      this.remoteStreamService.disconnectInputChannel();
      setTimeout(() => {
        this.remoteStreamService.connectInputChannel(overrideHost);
      }, 1000);
    }

    // In order to keep things simple for now we just reconnect the host, when the session or the stream is reloaded.
    // If another user has input assigned, this user will be disconnected.
    if (
      this.sessionService.userIsHost(this.session, this.userProfile) &&
      inputOffer &&
      inputOffer.state === InputOfferState.InputAssigned
    ) {
      this.inputOfferService.revokeInputOffer();
    }
  }

  // Input Control Management
  // ===================================

  async onOfferReceived(): Promise<void> {
    if (this.offerReceivedModalOpen) {
      return;
    }

    this.resolvedInputOffer = await this.inputOfferService.getResolvedInputOffer();

    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.message = `${this.resolvedInputOffer?.from?.name} wants you to take control.`;
    modalRef.result.then(
      async (result) => {
        switch (result) {
          case 'confirm':
            modalRef.close();
            this.inputOfferService.acceptInputOffer();
            break;
          case 'cancel': {
            modalRef.close();
            this.inputOfferService.declineInputOffer();
          }
        }
      },
      (reason) => {
        modalRef.close();
        this.inputOfferService.declineInputOffer();
      }
    );

    this.changeDetectorRef.detectChanges();
  }

  /**
   * The other user accepted the input offer -> Disconnect from input and notify other user
   */
  async onOfferAccepted(): Promise<void> {
    this.resolvedInputOffer = await this.inputOfferService.getResolvedInputOffer();
    this.remoteStreamService.disconnectInputChannel();
    this.inputOfferService.assignInput();
    this.changeDetectorRef.detectChanges();
    this.toastService.defaultToast(`${this.resolvedInputOffer?.to.name} now controls the application`);
  }

  async onInputAssigned(): Promise<void> {
    this.resolvedInputOffer = await this.inputOfferService.getResolvedInputOffer();
    this.changeDetectorRef.detectChanges();

    const overrideHost = true;
    this.remoteStreamService.connectInputChannel(overrideHost);
  }

  async onOfferDeclined(): Promise<void> {
    this.resolvedInputOffer = await this.inputOfferService.getResolvedInputOffer();
    this.changeDetectorRef.detectChanges();

    if (this.sessionService.userIsHost(this.session, this.userProfile)) {
      setTimeout(() => {
        this.remoteStreamService.connectInputChannel();
      }, 1000);
    } else {
      this.remoteStreamService.disconnectInputChannel();
    }

    this.toastService.defaultToast(`${this.resolvedInputOffer?.from?.name} now controls the application again.`);
    this.changeDetectorRef.detectChanges();
  }
}
