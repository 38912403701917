import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
  issuer: environment.keycloak.issuer,
  loginUrl: environment.keycloak.url_login,
  logoutUrl: environment.keycloak.url_logout,
  tokenEndpoint: environment.keycloak.url_token,
  userinfoEndpoint: environment.keycloak.url_userInfo,
  revocationEndpoint: environment.keycloak.url_revocation,
  redirectUri: window.location.origin,
  clientId: environment.keycloak.client,
  scope: 'openid',
  dummyClientSecret: 'our-little-secret',
  responseType: 'code',
  showDebugInformation: false,
};
