import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Subject, Subscription, interval } from 'rxjs';
import Peer from '../libraries/peer';

export interface ConnectionReport {
  id: string;
  kind: string;
  codec: string;
  decoder: string;
  width: number;
  height: number;
  framerate: number;
  bitrate_kbit_s: number;
  timestamp: number;
}

@Injectable({
  providedIn: 'root',
})
export class ConnectionReportService {
  reportUpdate = new Subject<ConnectionReport>();
  onConnectionEstablished = new Subject();
  onConnectionBad = new Subject();
  onConnectionTendToBreak = new Subject<Boolean>();
  onConnectionLost = new Subject();

  private PAUSE_VIDEO_FRAMERATE_THRESHOLD = 10;
  private PAUSE_VIDEO_BITRATE_THRESHOLD = 100;
  reportId = '';

  private subscription: Subscription;
  private lastReport: RTCStatsReport | undefined;

  private connectionStartTimestamp = 0;
  private zeroBitrateStartTimeframe = 0;
  private lowBitrateStartTimeframe = 0;
  private hadRunningConnection = false;

  private get defaultConnectionReport(): ConnectionReport {
    const connectionReport = {} as ConnectionReport;

    connectionReport.id = 'unknown';
    connectionReport.kind = 'unknown';
    connectionReport.codec = 'unknown';
    connectionReport.decoder = 'unknown';
    connectionReport.width = 0;
    connectionReport.height = 0;
    connectionReport.framerate = 0;
    connectionReport.timestamp = 0;
    connectionReport.bitrate_kbit_s = 0;

    return connectionReport;
  }

  constructor(private logger: NGXLogger) {}

  start(connectionId: string, peer: Peer) {
    this.logger.debug('[ConnectionStatsService] start', connectionId);

    if (!peer || !connectionId) {
      this.logger.debug('[ConnectionStatsService] peer or connectionId is undefined');
      return;
    }

    this.reset();

    this.subscription = interval(1000).subscribe(async () => {
      let reportUpdate: ConnectionReport;

      const report = await peer.getStats(connectionId);
      reportUpdate = this.getConnectionReport(report, this.lastReport);

      this.checkConnectionHealth(reportUpdate);
      if (reportUpdate.id != this.reportId) {
        this.reportId = reportUpdate.id;
      }
      this.reportUpdate.next(reportUpdate);

      if (!!report) {
        this.lastReport = report;
      }
    });
  }

  stop() {
    this.logger.debug('[ConnectionStatsService] stop');
    this.reportUpdate.next(this.defaultConnectionReport);

    this.reset();
  }

  reset() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.lastReport = undefined;
    this.hadRunningConnection = false;
    this.zeroBitrateStartTimeframe = 0;
    this.lowBitrateStartTimeframe = 0;
  }

  getConnectionReport(report: RTCStatsReport, lastReport: RTCStatsReport | undefined): ConnectionReport {
    const connectionReport = this.defaultConnectionReport;

    if (!report) {
      return connectionReport;
    }

    report.forEach((stat) => {
      if (stat.type === 'inbound-rtp') {
        connectionReport.id = stat.id;
        connectionReport.kind = stat.kind;
        connectionReport.codec = stat.codecId;
        connectionReport.decoder = stat.decoderImplementation;
        connectionReport.width = stat.frameWidth;
        connectionReport.height = stat.frameHeight;
        connectionReport.framerate = stat.framesPerSecond;
        connectionReport.timestamp = stat.timestamp;

        if (lastReport && (lastReport as any).has(stat.id)) {
          const lastStats = (lastReport as any).get(stat.id);
          const duration = (stat.timestamp - lastStats.timestamp) / 1000;
          const bitrate = (8 * (stat.bytesReceived - lastStats.bytesReceived)) / duration / 1000;
          connectionReport.bitrate_kbit_s = Math.round(bitrate);
        }
      }
    });

    return connectionReport;
  }

  checkConnectionHealth(reportUpdate: ConnectionReport) {
    if (reportUpdate.bitrate_kbit_s > 0) {
      this.hadRunningConnection = true;
      this.onConnectionEstablished?.next({});
    }

    if (this.connectionStartTimestamp == 0 && reportUpdate.timestamp > 0) {
      this.connectionStartTimestamp = reportUpdate.timestamp;
    }

    // Detect lost connection

    if (this.hadRunningConnection && reportUpdate.bitrate_kbit_s > 0) {
      this.zeroBitrateStartTimeframe = 0;
    }

    if (this.hadRunningConnection && reportUpdate.bitrate_kbit_s == 0 && this.zeroBitrateStartTimeframe == 0) {
      this.zeroBitrateStartTimeframe = reportUpdate.timestamp;
    }

    if (this.zeroBitrateStartTimeframe > 0 && reportUpdate.timestamp - this.zeroBitrateStartTimeframe > 1000) {
      this.logger.debug('[ConnectionStatsService] Connection is lost');
      this.onConnectionLost?.next({});
    }

    // Detect bad connection
    if (
      reportUpdate.framerate > this.PAUSE_VIDEO_FRAMERATE_THRESHOLD &&
      reportUpdate.bitrate_kbit_s > this.PAUSE_VIDEO_BITRATE_THRESHOLD
    ) {
      this.onConnectionTendToBreak?.next(false);
    } else {
      this.onConnectionTendToBreak?.next(true);
    }

    if (reportUpdate.bitrate_kbit_s > 300) {
      this.lowBitrateStartTimeframe = 0;
    }

    if (
      this.hadRunningConnection &&
      reportUpdate.bitrate_kbit_s > 0 &&
      reportUpdate.bitrate_kbit_s < 500 &&
      this.lowBitrateStartTimeframe == 0
    ) {
      this.lowBitrateStartTimeframe = reportUpdate.timestamp;
    }

    if (this.lowBitrateStartTimeframe > 0 && reportUpdate.timestamp - this.lowBitrateStartTimeframe > 2000) {
      this.logger.debug('[ConnectionStatsService] Connection is bad');
      this.onConnectionBad?.next({});
    }
  }
}
