<div id="container">
  <h1>Receiver Sample</h1>

  <div id="warning" hidden="true"></div>

  <div id="player"></div>

  <div class="box">
    <span>Codec preferences:</span>
    <select id="codecPreferences" disabled>
      <option selected value="">Default</option>
    </select>
  </div>

  <div class="box">
    <span>Lock Cursor to Player:</span>
    <input type="checkbox" id="lockMouseCheck" />
  </div>

  <p>
    For more information about sample, see
    <a href="https://docs.unity3d.com/Packages/com.unity.renderstreaming@3.1/manual/sample-broadcast.html"
      >Broadcast sample</a
    >
    document page.
  </p>

  <div id="message"></div>

  <section>
    <a
      href="https://github.com/Unity-Technologies/UnityRenderStreaming/tree/develop/WebApp/client/public/receiver"
      title="View source for this page on GitHub"
      id="viewSource"
      >View source on GitHub</a
    >
  </section>
</div>

<script type="text/javascript" src="https://unpkg.com/@ungap/event-target"></script>
<script src="https://unpkg.com/event-target@latest/min.js"></script>
<script src="https://unpkg.com/resize-observer-polyfill@1.5.0/dist/ResizeObserver.global.js"></script>
<script src="https://cdn.polyfill.io/v2/polyfill.min.js?features=IntersectionObserver"></script>
<script type="module" src="js/main.js"></script>
