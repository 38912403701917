<div class="modal-header">
  <h4 class="modal-title">{{ 'modal.control_panel.title' | translate }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <ng-container *ngIf="users.length > 0">
    <h5>{{ 'modal.control_panel.session_users' | translate }}</h5>
    <ul class="visitors_list fs-6">
      <li *ngFor="let user of users">
        <div class="visitors_list__info">
          <div class="visitors_list__name">{{ user.name }}</div>
          <div *ngIf="userIsStreamHost(user)" class="visitors_list__isHost">
            {{ 'modal.control_panel.host' | translate }}
          </div>
          <div *ngIf="user.isAnonymous && !userIsStreamHost(user)" class="visitors_list__isGuest">
            {{ 'modal.control_panel.external' | translate }}
          </div>

          <fa-icon
            *ngIf="isStreamHost() && !userIsStreamHost(user)"
            class="text-danger"
            (click)="removeUser(user)"
            [icon]="faUserXmark"
            placement="bottom"
            [ngbTooltip]="'tooltip.remove_users_from_session' | translate"
          ></fa-icon>
        </div>
        <div class="visitors_list__action">
          <button
            type="button"
            class="btn btn-outline-dark btn-sm"
            *ngIf="canOfferInput(user)"
            (click)="offerInput(user)"
          >
            {{ 'action.give_input' | translate }}
          </button>
          <button type="button" class="btn btn-danger btn-sm" *ngIf="canRevokeInput(user)" (click)="revokeInput()">
            {{ 'action.remove_input' | translate }}
          </button>
        </div>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="showWaitingRoom()">
    <h5>{{ ' modal.control_panel.waiting_room' | translate }}</h5>
    <ul class="visitors_list">
      <li *ngFor="let user of waitingUsers">
        <div class="visitors_list__info">
          <div class="waiting_room_list__name">{{ user.name }}</div>
        </div>
        <div class="visitors_list__action">
          <button class="btn btn-danger btn-sm" (click)="declineUserInWaitingRoom(user)">
            {{ 'action.decline' | translate }}
          </button>
          <button class="btn btn-success btn-sm" (click)="acceptUserInWaitingRoom(user)">
            {{ 'action.accept' | translate }}
          </button>
        </div>
      </li>
    </ul>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">
    {{ 'action.close' | translate }}
  </button>
</div>
