import { Injectable } from '@angular/core';
import { firstValueFrom, timeout } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Device } from '../model/device';
import { UserProfile } from '../model/user-profile';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class DeviceDiscoveryService {
  constructor(private http: HttpClient, private logger: NGXLogger) {}

  async getDeviceList(): Promise<any[]> {
    const url = environment.remotely.base_url + environment.remotely.endpoint.devices;

    const headerDict = {
      'Content-Type': 'application/json',
      OrganizationID: environment.remotely.organization,
      Authorization: environment.remotely.secret,
    };

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    try {
      const result = await firstValueFrom(this.http.get<any[]>(url, requestOptions));
      return result;
    } catch (error) {
      return [];
    }
  }

  async startRemoteStreamSignaling(): Promise<any> {}

  async populateRemoteStreamLinks(devices: Device[], userProfile: UserProfile): Promise<Device[]> {
    if (!devices || devices.length === 0 || !userProfile || !userProfile.devices || userProfile.devices.length === 0) {
      return [];
    }

    const userDevices = devices.filter((device) => userProfile.devices.includes(device.deviceName) && device.isOnline);

    await Promise.all(
      userDevices.map(async (userDevice) => {
        if (userDevice?.id) {
          const url = environment.remotely.base_url + environment.remotely.endpoint.remote + '/' + userDevice.id;
          const headerDict = {
            Accept: 'text/plain',
            'Content-Type': 'text/plain',
            OrganizationID: environment.remotely.organization,
            Authorization: environment.remotely.secret,
          };

          const requestOptions = {
            headers: new HttpHeaders(headerDict),
            responseType: 'text' as const,
          };
          const device = devices.find((device) => device.id === userDevice.id);

          try {
            const result = await firstValueFrom(this.http.get(url, requestOptions).pipe(timeout(5000)));
            if (device) {
              device.remoteUrl = result;

              if (userProfile.canControlStream && device.remoteUrl) {
                device.remoteUrl = device.remoteUrl.replace('&viewonly=True', '');
              }
            }
          } catch (error) {
            this.logger.error(error);
            if (device) {
              device.isOnline = false;
              device.remoteUrl = '';
            }
          }
        }
      })
    );

    // only show devices that a user can access
    devices = devices.filter((device) => userProfile.devices.includes(device.deviceName));
    return devices.sort((a, b) => (a.isOnline === b.isOnline ? 0 : a.isOnline ? -1 : 1));
  }

  generateVisitorLink(device: Device, canControlStream: boolean) {
    /*
      Use the same link that the device already generated. This is because when the admin started the stream,
      visitors can join this session. This proved to be the most stable solution at the moment. The links expiore after
      the defined time.
    */
    let visitorLink = device.remoteUrl;
    visitorLink.replace('&viewonly=True', '');
    if (!canControlStream) {
      visitorLink = visitorLink + '&viewonly=True';
    }
    return visitorLink;
  }
}
