import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthGuardService } from 'src/app/guards/auth-guard';

@Component({
  selector: 'app-custom-routing',
  templateUrl: './custom-routing.component.html',
  styleUrls: ['./custom-routing.component.scss'],
})
export class CustomRoutingComponent implements OnInit {
  page: string = 'login';
  language: string = 'en';

  routes = [
    { path: 'login' },
    { path: 'devices', checkCanActivate: true },
    { path: 'stream' }, // Check permissions in stream to allow anonymous users to join session
    { path: 'waiting-room' },
    { path: 'spectator-stream' },
  ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authGuard: AuthGuardService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      const param = params.get('page');
      this.page = 'login';

      this.setLanguage(params);

      this.routes.forEach((route) => {
        if (route.path === param) {
          if (!route.checkCanActivate || this.authGuard.canActivate()) {
            this.page = param;
          }
        }
      });
    });
  }

  setLanguage(params: ParamMap): void {
    let language = params.get('lang');

    if (!language) {
      language = localStorage.getItem('language');
    }
    if (!language) {
      language = 'en';
    }
    if (!['en', 'de'].includes(language)) {
      language = 'en';
    }

    if (language !== this.language) {
      this.translate.use(language);
    }
    this.language = language;
    localStorage.setItem('language', language);

    // update route so that it contains the language
    if (!params.get('lang')) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { lang: this.language },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    }
  }
}
