import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  passwordVisible = false;

  username = '';
  password = '';

  constructor(private oauthService: OAuthService, private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    setTimeout(() => {
      if (this.authService.isAuthenticated()) {
        this.router.navigate([''], { queryParams: { page: 'devices' } });
      }
    }, 500);
  }

  togglePasswordVisible(): void {
    this.passwordVisible = !this.passwordVisible;
  }

  async login(): Promise<void> {
    this.oauthService.initLoginFlow();
  }
}
