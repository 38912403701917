import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from '../config/auth-config';
import { UserProfile } from '../model/user-profile';
import { Md5 } from 'ts-md5';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private oauthService: OAuthService) {}

  private parsedUserProfile = {} as UserProfile;

  async init(): Promise<void> {
    this.oauthService.configure(authConfig);
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
    this.oauthService.setupAutomaticSilentRefresh();
  }

  async getUserProfile(includeAnonymous = false): Promise<UserProfile | undefined> {
    if (!this.isAuthenticated()) {
      if (includeAnonymous) {
        return this.getAnonymousUserProfile();
      }
      return undefined;
    } else {
      try {
        const userProfileData = await this.oauthService.loadUserProfile();
        this.parsedUserProfile = this.parseUserProfile(userProfileData);
        return this.parsedUserProfile;
      } catch (error) {
        return undefined;
      }
    }
  }

  getOrCreateAnonymousUserProfile(): UserProfile {
    let anonymousUserProfile = this.getAnonymousUserProfile();
    if (anonymousUserProfile) {
      return anonymousUserProfile;
    } else {
      const uuid = uuidv4();
      const anonymousUserProfile = {
        id: uuid,
        name: `Guest_${uuid.substring(0, 8)}`,
        isAnonymous: true,
      } as UserProfile;

      localStorage.setItem('anonymousUserProfile', JSON.stringify(anonymousUserProfile));
      return anonymousUserProfile;
    }
  }

  getAnonymousUserProfile(): UserProfile | undefined {
    let anonymousUserProfileStr = localStorage.getItem('anonymousUserProfile');
    if (anonymousUserProfileStr) {
      const anonymousUserProfile = JSON.parse(anonymousUserProfileStr) as UserProfile;
      anonymousUserProfile.isAnonymous = true;
      return anonymousUserProfile;
    } else {
      return;
    }
  }

  updateAnonymouseUserProfile(userProfile: UserProfile): void {
    localStorage.setItem('anonymousUserProfile', JSON.stringify(userProfile));
  }

  async logout(): Promise<void> {
    this.oauthService.logOut();
  }

  isAuthenticated(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  /**
   * Returns a unique device id for the current user and creates one if it does not exist.
   * This is used to identify the current user's device when logged in multiple times
   */
  get uniqueBrowserId(): string {
    const storedUniqueBrowserId = localStorage.getItem('xrstager_uniqueBrowserId');
    if (!storedUniqueBrowserId) {
      const uuid = uuidv4();
      localStorage.setItem('xrstager_uniqueBrowserId', uuid);
      return uuid;
    } else {
      return storedUniqueBrowserId;
    }
  }

  get canAccessChat(): boolean {
    return this.parsedUserProfile.canAccessChat;
  }

  get canAccessCloud(): boolean {
    return this.parsedUserProfile.canAccessCloud;
  }

  get canAccessSettings(): boolean {
    return this.parsedUserProfile.canAccessUpload;
  }

  get canAccessUpload(): boolean {
    return this.parsedUserProfile.canAccessSettings;
  }

  get canGenerateVisitorLinks(): boolean {
    return this.parsedUserProfile.canGenerateVisitorLinks;
  }

  get canGenerateControllableVisitorLinks(): boolean {
    return this.parsedUserProfile.canGenerateVisitorLinks;
  }

  private parseUserProfile(userProfileInfo: any): UserProfile {
    const md5 = new Md5();

    return {
      id: md5.appendStr(userProfileInfo?.info?.email ?? '').end() as string,
      email: userProfileInfo?.info?.email,
      name: userProfileInfo?.info?.name,
      preferredUsername: userProfileInfo?.info?.preferred_username,
      isAnonymous: false,
      devices: userProfileInfo?.info?.Devices?.replace(/ /g, '').split(','),
      applications: userProfileInfo?.info?.Applications?.replace(/ /g, '').split(','),
      canControlStream: userProfileInfo?.info?.CanControlStream ?? false,
      canAccessChat: userProfileInfo?.info?.CanAccessChat ?? false,
      canAccessCloud: userProfileInfo?.info?.CanAccessCloud ?? false,
      canAccessUpload: userProfileInfo?.info?.CanAccessUpload ?? false,
      canAccessSettings: userProfileInfo?.info?.CanAccessSettings ?? false,
      canGenerateVisitorLinks: userProfileInfo?.info?.CanGenerateVisitorLinks ?? false,
      canGenerateControllableVisitorLinks: userProfileInfo?.info?.CanGenerateControllableVisitorLinks ?? false,
      canUseRemotely: userProfileInfo?.info?.CanUseRemotely ?? false,
      canAdministrateStream: userProfileInfo?.info?.CanAdministrateStream ?? false,
      directApplicationConnection: userProfileInfo?.info?.DirectApplicationConnection ?? undefined,
    };
  }
}
