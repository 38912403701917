import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';
import packageInfo from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = environment.title;
  version = packageInfo.version;

  constructor(private authService: AuthService, private router: Router) {
    authService.init();
  }

  async logout(): Promise<void> {
    this.authService.logout();
  }

  isLanguageSelected(language: string): boolean {
    return language === localStorage.getItem('language');
  }

  selectLanguage(language: string): void {
    localStorage.setItem('language', language);
    this.router.navigate([], {
      queryParams: { lang: language },
      queryParamsHandling: 'merge',
    });
  }
}
