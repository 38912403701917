export class Signaling extends EventTarget {
  constructor(logger = null) {
    super();
    this.logger = logger;
    this.signalingServerUrl = location.origin;
    this.running = false;
    this.sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec));
  }

  debug(str) {
    if (this.logger && this.logger.debug) {
      this.logger.debug(`[Signaling] ${str}`);
    } else {
      console.debug(`[Signaling] ${str}`);
    }
  }

  warn(str) {
    if (this.logger && this.logger.warn) {
      this.logger.warn(`[Signaling] ${str}`);
    } else {
      console.warn(`[Signaling] ${str}`);
    }
  }

  headers() {
    if (this.sessionId !== undefined) {
      return {
        'Content-Type': 'application/json',
        'Session-Id': this.sessionId,
      };
    } else {
      return { 'Content-Type': 'application/json' };
    }
  }

  get interval() {
    return 1000;
  }

  url(method, parameter = '') {
    let ret = this.signalingServerUrl + '/signaling';
    if (method) ret += '/' + method;
    if (parameter) ret += '?' + parameter;
    return ret;
  }

  async start(signalingServerUrl) {
    this.debug(`[Signaling] start [${signalingServerUrl}]`);

    if (this.running) {
      return;
    }

    this.signalingServerUrl = signalingServerUrl;

    this.running = true;
    while (!this.sessionId) {
      const createResponse = await fetch(this.url(''), {
        method: 'PUT',
        headers: this.headers(),
      });
      const session = await createResponse.json();
      this.sessionId = session.sessionId;

      if (!this.sessionId) {
        await this.sleep(this.interval);
      }
    }

    this.loopGetAll();
  }

  async loopGetAll() {
    this.debug(`[Signaling] loopGetAll`);

    let lastTimeRequest = Date.now() - 30000;
    while (this.running) {
      let res;
      try {
        res = await this.getAll(lastTimeRequest);
      } catch (error) {
        this.warn('getAll' + error);
        return;
      }

      if (!res || !res.ok) {
        return;
      }

      lastTimeRequest = Date.now();

      let data;
      try {
        data = await res.json();
      } catch (error) {
        this.warn('res.json()' + error);
        return;
      }
      const messages = data.messages;

      for (const msg of messages) {
        switch (msg.type) {
          case 'connect':
            break;
          case 'disconnect':
            this.dispatchEvent(new CustomEvent('disconnect', { detail: msg }));
            break;
          case 'offer':
            this.dispatchEvent(new CustomEvent('offer', { detail: msg }));
            break;
          case 'answer':
            this.dispatchEvent(new CustomEvent('answer', { detail: msg }));
            break;
          case 'candidate':
            this.dispatchEvent(new CustomEvent('candidate', { detail: msg }));
            break;
          default:
            break;
        }
      }
      await this.sleep(this.interval);
    }
  }

  async stop() {
    this.debug(`[Signaling] stop`);
    this.running = false;
    try {
      await fetch(this.url(''), { method: 'DELETE', headers: this.headers() });
    } catch (error) {
      this.warn(error);
    }
    this.sessionId = null;
  }

  async createConnection(connectionId) {
    this.debug(`[Signaling] createConnection [${connectionId}]`);

    const data = { connectionId: connectionId };
    const res = await fetch(this.url('connection'), {
      method: 'PUT',
      headers: this.headers(),
      body: JSON.stringify(data),
    });
    const json = await res.json();
    this.debug(`Signaling: HTTP create connection, connectionId: ${json.connectionId}, polite:${json.polite}`);

    this.dispatchEvent(new CustomEvent('connect', { detail: json }));
    return json;
  }

  async deleteConnection(connectionId) {
    this.debug(`[Signaling] deleteConnection [${connectionId}]`);

    return new Promise((resolve) => {
      const data = { connectionId: connectionId };
      fetch(this.url('connection'), {
        method: 'DELETE',
        headers: this.headers(),
        body: JSON.stringify(data),
      })
        .then(async (res) => {
          if (res.ok) {
            const json = await res.json();
            this.dispatchEvent(new CustomEvent('disconnect', { detail: json }));
          } else {
            this.warn(`[Signaling] Error deleting connection (404)`);
          }
          resolve();
        })
        .catch((error) => {
          this.warn(`[Signaling] Error deleting connection (${error})`);
        });
    });
  }

  async sendOffer(connectionId, sdp) {
    const data = { sdp: sdp, connectionId: connectionId };
    this.debug('sendOffer:' + JSON.stringify(data));
    await fetch(this.url('offer'), {
      method: 'POST',
      headers: this.headers(),
      body: JSON.stringify(data),
    });
  }

  async sendAnswer(connectionId, sdp) {
    const data = { sdp: sdp, connectionId: connectionId };
    this.debug('sendAnswer:' + JSON.stringify(data));
    await fetch(this.url('answer'), {
      method: 'POST',
      headers: this.headers(),
      body: JSON.stringify(data),
    });
  }

  async sendCandidate(connectionId, candidate, sdpMid, sdpMLineIndex) {
    const data = {
      candidate: candidate,
      sdpMLineIndex: sdpMLineIndex,
      sdpMid: sdpMid,
      connectionId: connectionId,
    };
    this.debug('sendCandidate' + JSON.stringify(data));
    await fetch(this.url('candidate'), {
      method: 'POST',
      headers: this.headers(),
      body: JSON.stringify(data),
    });
  }

  async getConnection() {
    return await fetch(this.url(`connection`), {
      method: 'GET',
      headers: this.headers(),
    });
  }

  async getOffer(fromTime = 0) {
    return await fetch(this.url(`offer`, `fromtime=${fromTime}`), {
      method: 'GET',
      headers: this.headers(),
    });
  }

  async getAnswer(fromTime = 0) {
    return await fetch(this.url(`answer`, `fromtime=${fromTime}`), {
      method: 'GET',
      headers: this.headers(),
    });
  }

  async getCandidate(fromTime = 0) {
    return await fetch(this.url(`candidate`, `fromtime=${fromTime}`), {
      method: 'GET',
      headers: this.headers(),
    });
  }

  async getAll(fromTime = 0) {
    try {
      const response = await fetch(this.url(``, `fromtime=${fromTime}`), {
        method: 'GET',
        headers: this.headers(),
      });
      return response;
    } catch (error) {}
  }
}

export class WebSocketSignaling extends EventTarget {
  constructor() {
    super();
    this.sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec));

    this.debug = (msg) => {};
    this.warn = (msg) => {};

    let websocketUrl;
    if (location.protocol === 'https:') {
      websocketUrl = 'wss://' + location.host;
    } else {
      websocketUrl = 'ws://' + location.host;
    }

    this.websocket = new WebSocket(websocketUrl);
    this.connectionId = null;

    this.websocket.onopen = () => {
      this.isWsOpen = true;
    };

    this.websocket.onclose = () => {
      this.isWsOpen = false;
    };

    this.websocket.onmessage = (event) => {
      let msg;
      try {
        msg = JSON.parse(event.data);
      } catch (error) {
        this.warn(error);
        return;
      }

      if (!msg || !this) {
        return;
      }

      this.debug(msg);

      switch (msg.type) {
        case 'connect':
          this.dispatchEvent(new CustomEvent('connect', { detail: msg }));
          break;
        case 'disconnect':
          this.dispatchEvent(new CustomEvent('disconnect', { detail: msg }));
          break;
        case 'offer':
          this.dispatchEvent(
            new CustomEvent('offer', {
              detail: {
                connectionId: msg.from,
                sdp: msg.data.sdp,
                polite: msg.data.polite,
              },
            })
          );
          break;
        case 'answer':
          this.dispatchEvent(
            new CustomEvent('answer', {
              detail: { connectionId: msg.from, sdp: msg.data.sdp },
            })
          );
          break;
        case 'candidate':
          this.dispatchEvent(
            new CustomEvent('candidate', {
              detail: {
                connectionId: msg.from,
                candidate: msg.data.candidate,
                sdpMLineIndex: msg.data.sdpMLineIndex,
                sdpMid: msg.data.sdpMid,
              },
            })
          );
          break;
        default:
          break;
      }
    };
  }

  get interval() {
    return 100;
  }

  async start() {
    while (!this.isWsOpen) {
      await this.sleep(100);
    }
  }

  async stop() {
    this.websocket.close();
    while (this.isWsOpen) {
      await this.sleep(100);
    }
  }

  createConnection(connectionId) {
    const sendJson = JSON.stringify({
      type: 'connect',
      connectionId: connectionId,
    });
    this.debug(sendJson);
    this.websocket.send(sendJson);
  }

  deleteConnection(connectionId) {
    const sendJson = JSON.stringify({
      type: 'disconnect',
      connectionId: connectionId,
    });
    this.debug(sendJson);
    this.websocket.send(sendJson);
  }

  sendOffer(connectionId, sdp) {
    const data = { sdp: sdp, connectionId: connectionId };
    const sendJson = JSON.stringify({
      type: 'offer',
      from: connectionId,
      data: data,
    });
    this.debug(sendJson);
    this.websocket.send(sendJson);
  }

  sendAnswer(connectionId, sdp) {
    const data = { sdp: sdp, connectionId: connectionId };
    const sendJson = JSON.stringify({
      type: 'answer',
      from: connectionId,
      data: data,
    });
    this.debug(sendJson);
    this.websocket.send(sendJson);
  }

  sendCandidate(connectionId, candidate, sdpMLineIndex, sdpMid) {
    const data = {
      candidate: candidate,
      sdpMLineIndex: sdpMLineIndex,
      sdpMid: sdpMid,
      connectionId: connectionId,
    };
    const sendJson = JSON.stringify({
      type: 'candidate',
      from: connectionId,
      data: data,
    });
    this.debug(sendJson);
    this.websocket.send(sendJson);
  }
}
