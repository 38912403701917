import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { concatMap, interval, Observable, of, Subscription } from 'rxjs';
import { Device } from 'src/app/model/device';
import { RemotePeer } from 'src/app/model/remote-peer';
import { XRSUser } from 'src/app/model/xrs-user';
import { XrsApplicationService } from 'src/app/services/xrs-application.service';
import { AuthService } from 'src/app/services/auth.service';
import { XrsUserService } from 'src/app/services/xrs-user.service';
import { environment } from 'src/environments/environment';
import { DisconnectReason, StateService, StateTopic } from 'src/app/services/state.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserProfile } from 'src/app/model/user-profile';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StreamAdministrationModalComponent } from '../modals/stream-settings-modal/stream-admin-modal.component';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { SessionService } from 'src/app/services/session.service';
import { ApplicationStatus } from 'src/app/model/xrs-application';
import { DeviceDiscoveryService } from 'src/app/services/device-discovery.service';

@Component({
  selector: 'app-devices-panel',
  templateUrl: './devices-panel.component.html',
  styleUrls: ['./devices-panel.component.scss'],
})
export class DevicesPanelComponent implements OnInit, OnDestroy, AfterViewInit {
  remoteDevices: Device[] = [];
  remotePeers: Observable<RemotePeer[]> = of([]);
  availablePeers: Observable<RemotePeer[]>;
  xrsUsers: Observable<XRSUser[]>;
  userProfile: UserProfile | undefined;

  initializing = true;
  isLoadingDevices = false;

  refreshIntervalSubscription: Subscription;
  authorizedIntervalSubscription: Subscription;

  visitorLinkPanelOpen = false;
  generatedVisitorLink = 'somelink';
  visitorLinkRemotePeer: RemotePeer;
  visitorLinkExpirationTimeInMinutes = 15;
  visitorLinkCanControl = false;
  copyToClipboardSuccess = false;

  counter = 0;

  faWrench = faWrench;

  constructor(
    private router: Router,
    public authService: AuthService,
    private xrsUserService: XrsUserService,
    private xrsApplicationService: XrsApplicationService,
    private stateService: StateService,
    private sessionService: SessionService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private deviceDiscoveryService: DeviceDiscoveryService,
    private cd: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.userProfile = await this.authService.getUserProfile();

    await this.checkUserHasDirectApplicationAccess();

    this.isLoadingDevices = true;
    this.loadDeviceList();

    this.handlePendingErrorStates();

    this.xrsUserService.resetPreviousUser();

    const source = interval(environment.remotely.refreshInterval);
    // this.refreshIntervalSubscription = source.subscribe((val) => this.loadDeviceList());
    this.authorizedIntervalSubscription = source.subscribe((val) => this.checkUserLoggedIn());

    const sessions = this.sessionService.getSessions();

    this.remotePeers = sessions.pipe(
      concatMap(async (sessions) => {
        const applications = await this.xrsApplicationService.getApplications();

        // Filter applications based on user profile
        const allowedApplications = this.userProfile?.applications ?? [];
        const filteredApplications = applications.filter((application) => {
          return allowedApplications.includes(application.id);
        });

        return filteredApplications.map((application) => {
          if (application.state === ApplicationStatus.Online || application.state === ApplicationStatus.Occupied) {
            const session = sessions.find((s) => s.applicationId === application.id);

            const availablePeer = {} as RemotePeer;

            availablePeer.applicationId = application.id;
            availablePeer.applicationName = application.name;
            availablePeer.connectionId = 'no-connection-id';
            availablePeer.streamRunning = !!session;
            availablePeer.streamOwner = session?.host;
            availablePeer.streamWatchers = session?.visitors ?? [];
            availablePeer.online = true;
            return availablePeer;
          } else {
            return {
              applicationId: application.id,
              applicationName: application.name,
              online: false,
            } as RemotePeer;
          }
        });
      })
    );

    this.remotePeers.subscribe((remotePeers) => {
      this.cd.detectChanges();
    });

    await this.authService.init();
  }

  ngAfterViewInit(): void {
    this.initializing = false;
    this.xrsUserService.resetPreviousUser();
  }

  ngOnDestroy(): void {
    this.refreshIntervalSubscription?.unsubscribe();
  }

  async loadDeviceList(): Promise<void> {
    this.userProfile = await this.authService.getUserProfile();
    if (this.userProfile && this.userProfile.canUseRemotely) {
      const devices = await this.deviceDiscoveryService.getDeviceList();
      this.remoteDevices = await this.deviceDiscoveryService.populateRemoteStreamLinks(devices, this.userProfile);
    }
  }

  getRemotePeerState(peer: RemotePeer): string {
    if (peer.online && peer.streamRunning) {
      return 'active';
    } else if (peer.online && !peer.streamRunning) {
      return 'online';
    } else {
      return 'offline';
    }
  }

  async checkUserLoggedIn(): Promise<void> {
    if (!this.authService.isAuthenticated()) {
      await this.router.navigate([''], { queryParams: { page: 'login' } });
    }
  }

  async checkUserHasDirectApplicationAccess(): Promise<void> {
    if (this.authService.isAuthenticated()) {
      this.userProfile = await this.authService.getUserProfile();

      if (this.userProfile && !!this.userProfile.directApplicationConnection) {
        await this.router.navigate([''], {
          queryParams: { page: 'stream', applicationId: this.userProfile.directApplicationConnection },
        });
        return;
      }
    }
  }

  async logout(): Promise<void> {
    await this.authService.logout();
    await this.router.navigate([''], { queryParams: { page: 'login' } });
  }

  async startStreamAsOwner(remotePeer: RemotePeer): Promise<void> {
    if (!remotePeer.online) {
      return;
    }

    await this.router.navigate([''], {
      queryParams: { page: 'stream', connectionId: remotePeer.connectionId, applicationId: remotePeer.applicationId },
    });
  }

  async startStreamAsSpectator(remotePeer: RemotePeer): Promise<void> {
    if (!remotePeer.online) {
      return;
    }

    await this.router.navigate([''], {
      queryParams: { page: 'stream', connectionId: remotePeer.connectionId, applicationId: remotePeer.applicationId },
    });
  }

  async openStreamSettingsModal(applicationId: string): Promise<void> {
    const application = await this.xrsApplicationService.getApplication(applicationId);
    const modalRef = this.modalService.open(StreamAdministrationModalComponent);
    modalRef.componentInstance.userProfile = this.userProfile;
    modalRef.componentInstance.application = application;
  }

  handlePendingErrorStates(): void {
    if (this.stateService.has(StateTopic.Error)) {
      const error = this.stateService.consume(StateTopic.Error);

      let toastMessage = '';
      switch (error?.reason) {
        case DisconnectReason.ClientDisconnect:
          toastMessage = 'Stream closed';
          break;
        case DisconnectReason.RemoteDisconnect:
          toastMessage = 'The connection was lost. Please try again';
          break;
        case DisconnectReason.InvalidApplication:
          toastMessage = 'Application could not be found. Please contact support';
          break;
        case DisconnectReason.AnonymousStreamHasNoOwner:
          toastMessage =
            'The application currently has no owner. Please wait for the stream to start before connecting';
          break;
      }

      this.toastService.defaultToast(toastMessage);
    }
  }
}
