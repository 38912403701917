import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InputOfferState } from 'src/app/model/input-types';
import { Session } from 'src/app/model/session';
import { UserProfile } from 'src/app/model/user-profile';
import { XRSUser } from 'src/app/model/xrs-user';
import { AuthService } from 'src/app/services/auth.service';
import { InputOfferService } from 'src/app/services/input-offer.service';
import { SessionService } from 'src/app/services/session.service';
import { XrsApplicationService } from 'src/app/services/xrs-application.service';

@Component({
  selector: 'app-controls-modal',
  templateUrl: './controls-modal.component.html',
  styleUrls: ['./controls-modal.component.scss'],
})
export class ControlsModalComponent implements OnInit {
  session: Session;
  filteredSessionUsers: XRSUser[];

  private userProfile: UserProfile;

  constructor(
    public activeModal: NgbActiveModal,
    private applicationService: XrsApplicationService,
    private authService: AuthService,
    private sessionService: SessionService,
    private inputOfferService: InputOfferService
  ) {}

  async ngOnInit(): Promise<void> {
    const userProfile = await this.authService.getUserProfile(true);
    if (userProfile) {
      this.userProfile = userProfile;
    } else {
      this.activeModal.dismiss();
      return;
    }

    const application = await this.applicationService.getApplicationFromUrl();
    const session = await this.sessionService.getSessionOnce(application?.id || '');
    if (session) {
      this.session = session;
      this.filteredSessionUsers = session.visitors;
    } else {
      this.activeModal.dismiss();
      return;
    }
  }

  visitorHasInput(): boolean {
    return this.inputOfferService.visitorHasInput();
  }

  offerInput(user: XRSUser): void {
    this.activeModal.dismiss();
    this.inputOfferService.offerInput(user.id);
  }

  revokeInput(): void {
    this.activeModal.dismiss();
    this.inputOfferService.revokeInputOffer();
  }

  declineInput(): void {
    this.activeModal.dismiss();
    this.inputOfferService.declineInputOffer();
  }

  canOfferInput(otherUser: XRSUser): boolean {
    const inputOffer = this.inputOfferService.getInputOffer();

    const isOwner = this.userIsHost();
    const otherIsSelf = otherUser.id === this.userProfile?.id;

    // Ignore if the present us is oneself and if we are not host
    if (otherIsSelf || !isOwner) {
      return false;
    }

    // True if there is no offer already -> everyone can receive the offer
    if (!inputOffer) {
      return true;
    }

    // If someone already has input then do not show the button to assign to another one
    const someoneAlreadyHasInput = inputOffer?.state === InputOfferState.InputAssigned;
    if (someoneAlreadyHasInput) {
      return false;
    }

    // If the otherone is our offer peer, then this is depending on the state of the offer
    const otherIsOfferPeer = otherUser.id === inputOffer.to;
    if (otherIsOfferPeer) {
      return (
        inputOffer.state <= InputOfferState.HostHasInput || inputOffer.state === InputOfferState.DeclinedRevokedOffer
      );
    } else {
      // If this is not our peer than we can offer the input to them
      return true;
    }
  }

  canRevokeInput(otherUser: XRSUser): boolean {
    const inputOffer = this.inputOfferService.getInputOffer();

    const isOwner = this.userIsHost();
    const isOfferRecipient = inputOffer?.to === otherUser.id;
    const isInCorrectState = !inputOffer
      ? false
      : inputOffer.state === InputOfferState.PendingOffer ||
        inputOffer.state === InputOfferState.AcceptedOffer ||
        inputOffer.state === InputOfferState.InputAssigned;

    return !!isOwner && isOfferRecipient && isInCorrectState;
  }

  userIsHost(): boolean {
    return this.sessionService.userIsHost(this.session, this.userProfile);
  }
}
