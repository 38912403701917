import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { StreamAdministrationModalComponent } from './stream-settings-modal/stream-admin-modal.component';
import { SharedModule } from 'src/app/modules/shared-module.module';

@NgModule({
  declarations: [ConfirmModalComponent, StreamAdministrationModalComponent],
  imports: [BrowserModule, NgbModule, SharedModule],
  exports: [ConfirmModalComponent],
  providers: [],
  bootstrap: [],
})
export class ModalsModule {}
