import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'src/app/components/modals/confirm-modal/confirm-modal.component';
import { InputOfferState } from 'src/app/model/input-types';
import { Session } from 'src/app/model/session';
import { UserProfile } from 'src/app/model/user-profile';
import { XRSUser } from 'src/app/model/xrs-user';
import { AuthService } from 'src/app/services/auth.service';
import { InputOfferService } from 'src/app/services/input-offer.service';
import { SessionService } from 'src/app/services/session.service';
import { XrsApplicationService } from 'src/app/services/xrs-application.service';
import { faUserXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-visitors-modal',
  templateUrl: './visitors-modal.component.html',
  styleUrls: ['./visitors-modal.component.scss'],
})
export class VisitorsModalComponent implements OnChanges, OnInit {
  session: Session;
  users: XRSUser[] = [];
  waitingUsers: XRSUser[] = [];

  faUserXmark = faUserXmark;

  private userProfile: UserProfile | undefined;

  constructor(
    public activeModal: NgbActiveModal,
    private sessionService: SessionService,
    private applicationService: XrsApplicationService,
    private authService: AuthService,
    private modalService: NgbModal,
    private inputOfferService: InputOfferService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.userProfile = await this.authService.getUserProfile();
    const application = await this.applicationService.getApplicationFromUrl();

    if (!application) {
      return;
    }

    this.sessionService.getSession(application.id).subscribe((session) => {
      if (session) {
        if (this.session?.applicationId !== session.applicationId) {
          this.session = session;
        }

        const newUsers = [session.host, ...session.visitors];
        if (newUsers.length !== this.users.length) {
          this.users = newUsers;
        }

        if (this.waitingUsers.length !== session.waitingRoom.length) {
          this.waitingUsers = session?.waitingRoom ?? [];
        }
      } else {
        this.users = [];
        this.waitingUsers = [];
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.changeDetectorRef.detectChanges();
  }

  userIsStreamHost(user: XRSUser): boolean {
    return this.sessionService.otherUserIsHost(this.session, user);
  }

  isStreamHost(): boolean {
    return this.session?.host?.id === this.userProfile?.id;
  }

  removeUser(user: XRSUser): void {
    this.activeModal.close();

    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.message = `Do you really want to remove user '${user.name}' from the stream?`;
    modalRef.result.then(
      (result) => {
        switch (result) {
          case 'confirm':
            this.sessionService.removeUserFromSession(this.session, user);
            break;
          case 'close': {
            modalRef.close();
          }
        }
      },
      (reason) => {
        modalRef.close();
      }
    );
  }

  showWaitingRoom(): boolean {
    const userIsHost = this.sessionService.userIsHost(this.session, this.userProfile);
    const hasWaitingUsers = this.waitingUsers.length > 0;
    return userIsHost && hasWaitingUsers;
  }

  declineUserInWaitingRoom(user: XRSUser): void {
    this.sessionService.removeUserFromSession(this.session, user);
  }

  acceptUserInWaitingRoom(user: XRSUser): void {
    this.sessionService.acceptUserToSession(this.session, user);
  }

  // Input Control

  visitorHasInput(): boolean {
    return this.inputOfferService.visitorHasInput();
  }

  canOfferInput(otherUser: XRSUser): boolean {
    const inputOffer = this.inputOfferService.getInputOffer();

    const isOwner = this.isStreamHost();
    const otherIsSelf = otherUser.id === this.userProfile?.id;

    // Ignore if the present us is oneself and if we are not host
    if (otherIsSelf || !isOwner) {
      return false;
    }

    // True if there is no offer already -> everyone can receive the offer
    if (!inputOffer) {
      return true;
    }

    // If someone already has input then do not show the button to assign to another one
    const someoneAlreadyHasInput = inputOffer?.state === InputOfferState.InputAssigned;
    if (someoneAlreadyHasInput) {
      return false;
    }

    // If the otherone is our offer peer, then this is depending on the state of the offer
    const otherIsOfferPeer = otherUser.id === inputOffer.to;
    if (otherIsOfferPeer) {
      return (
        inputOffer.state <= InputOfferState.HostHasInput || inputOffer.state === InputOfferState.DeclinedRevokedOffer
      );
    } else {
      // If this is not our peer than we can offer the input to them
      return true;
    }
  }

  canRevokeInput(otherUser: XRSUser): boolean {
    const inputOffer = this.inputOfferService.getInputOffer();

    const isOwner = this.isStreamHost();
    const isOfferRecipient = inputOffer?.to === otherUser.id;
    const isInCorrectState = !inputOffer
      ? false
      : inputOffer.state === InputOfferState.PendingOffer ||
        inputOffer.state === InputOfferState.AcceptedOffer ||
        inputOffer.state === InputOfferState.InputAssigned;

    return !!isOwner && isOfferRecipient && isInCorrectState;
  }

  offerInput(user: XRSUser): void {
    this.inputOfferService.offerInput(user.id);
  }

  revokeInput(): void {
    this.inputOfferService.revokeInputOffer();
  }

  declineInput(): void {
    this.activeModal.dismiss();
    this.inputOfferService.declineInputOffer();
  }
}
