import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private subscriptions = new Map<string, Subscription[]>();

  add(tag: string, subscription: Subscription): void {
    if (!this.subscriptions.has(tag)) {
      this.subscriptions.set(tag, []);
    }
    this.subscriptions.get(tag)?.push(subscription);
  }

  unsubscribe(tag: string): void {
    this.subscriptions.get(tag)?.forEach((sub) => sub?.unsubscribe());
  }
}
