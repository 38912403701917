import { Injectable } from '@angular/core';

export enum StateTopic {
  General,
  Error,
}

export enum DisconnectReason {
  RemoteDisconnect,
  ClientDisconnect,
  AnonymousStreamHasNoOwner,
  RemovedFromStream,
  InvalidApplication,
  UserAlreadyInSession,
}

export interface StateMessage {
  topic: StateTopic;
  reason: DisconnectReason;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private internalState: StateMessage[] = [];

  add(state: StateMessage): void {
    let existingState = this.internalState.find((message) => message.topic === state.topic);
    if (existingState) {
      existingState = state;
    } else {
      this.internalState.push(state);
    }
  }

  has(topic: StateTopic): boolean {
    return !!this.internalState.find((message) => message.topic === topic);
  }

  getAll(): StateMessage[] {
    return this.internalState;
  }

  get(topic: StateTopic): StateMessage | undefined {
    return this.internalState.find((message) => message.topic === topic);
  }

  consume(topic: StateTopic): StateMessage | undefined {
    const message = this.internalState.find((message) => message.topic === topic);
    if (message) {
      this.internalState = this.internalState.filter((message) => message.topic !== topic);
    }
    return message;
  }
}
