import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
})
export class InviteComponent {
  shareLink = window.location.href;

  constructor(
    public activeModal: NgbActiveModal,
    private clipboardService: ClipboardService,
    private toastService: ToastService
  ) {}

  copyLink(): void {
    this.clipboardService.copy(window.location.href);
    this.toastService.defaultToast('Copied stream link to your clipboard');
    this.activeModal.dismiss();
  }
}
