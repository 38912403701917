<div id="waiting-room">
  <div *ngIf="application" class="container d-flex flex-column justify-content-center">
    <div class="title mb-3 font-weight-bold">{{ title }}</div>
    <div class="description mb-4">
      {{ description }}
      <div
        *ngIf="state === waitingRoomState.WaitingForHost || state === waitingRoomState.JoinSession"
        class="spinner-border spinner-border-sm text-primary ml-5"
        role="status"
      ></div>
    </div>
    <div class="name-controls mb-5">
      <input
        #username
        id="user_name"
        class="form-control mb-3 text-center"
        type="text"
        [(ngModel)]="userName"
        placeholder="User Name"
        [disabled]="state !== waitingRoomState.RegisterAtSession"
      />
      <div class="mb-3">
        <span>{{ 'waiting_room.join_session_as' | translate }} </span>
        <span style="font-weight: bold">"{{ this.userName }}"</span>
      </div>
    </div>

    <ng-container *ngIf="state === waitingRoomState.RegisterAtSession">
      <div class="start-stream-controls">
        <div (click)="joinSession()" class="btn btn-primary">{{ 'action.join_session' | translate }}</div>
      </div>
    </ng-container>
    <ng-container *ngIf="state === waitingRoomState.WaitingForHost || state === waitingRoomState.JoinSession">
      <div class="cancel-controls">
        <div (click)="cancel()" class="btn btn-danger">{{ 'action.leave_waiting_room' | translate }}</div>
      </div>
    </ng-container>
  </div>
</div>
