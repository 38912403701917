import { Injectable } from '@angular/core';
import Toastify from 'toastify-js';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  defaultToast(text: string, duration: number = 5000): void {
    Toastify({
      text,
      duration,
      close: true,
      gravity: 'bottom',
      position: 'right',
      stopOnFocus: true,
    }).showToast();
  }
}
