import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalsModule } from '../modals/modals.module';
import { AppMenuBarComponent } from './app-menu-bar.component';
import { ControlsModalComponent } from './modals/controls/controls-modal.component';
import { VisitorsModalComponent } from './modals/visitors/visitors-modal.component';
import { InviteComponent } from './modals/invite/invite.component';
import { SharedModule } from 'src/app/modules/shared-module.module';

@NgModule({
  declarations: [AppMenuBarComponent, VisitorsModalComponent, ControlsModalComponent, InviteComponent],
  imports: [BrowserModule, NgbModule, ModalsModule, FontAwesomeModule, SharedModule],
  exports: [AppMenuBarComponent],
  providers: [],
  bootstrap: [],
})
export class AppMenuBarModule {}
