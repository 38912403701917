<div *ngIf="initializing" id="loading-screen"></div>
<ng-container *ngIf="!initializing">
  <div class="logout" (click)="logout()"><span>&#8249;</span> {{ 'action.logout' | translate }}</div>
  <div class="tools_container">
    <a
      [ngClass]="{ disabled: !authService.canAccessChat }"
      [attr.href]="authService.canAccessChat ? 'https://chat.xrstager.online/home' : null"
      target="_blank"
      rel="noreferrer"
    >
      <img src="../../assets/Icon_Chat.png" alt="Chat" />
      <span>{{ 'appliaction.chat' | translate }}</span>
    </a>
    <a [ngClass]="{ disabled: !authService.canAccessUpload }">
      <img src="../../assets/Icon_Uploader.png" alt="Upload" />
      <span>{{ 'application.upload' | translate }}</span>
    </a>
    <a
      [ngClass]="{ disabled: !authService.canAccessCloud }"
      [attr.href]="authService.canAccessCloud ? 'https://cloud.xrstager.online/apps/files' : null"
      target="_blank"
      rel="noreferrer"
    >
      <img src="../../assets/Icon_Cloud.png" alt="Cloud" />
      <span>{{ 'application.cloud' | translate }}</span>
    </a>
    <a [ngClass]="{ disabled: !authService.canAccessSettings }">
      <img src="../../assets/Icon_Verwaltung.png" alt="Verwaltung" />
      <span>{{ 'application.settings' | translate }}</span>
    </a>
  </div>

  <div *ngIf="remotePeers" class="device_list">
    <ul>
      <li *ngFor="let remotePeer of remotePeers | async">
        <!-- Information Row -->
        <div class="device_list__info_row">
          <div class="device_item_name">{{ remotePeer.applicationName }}</div>
          <div class="device_item_is_online" [ngClass]="{ online: remotePeer.online, offline: !remotePeer.online }">
            {{ getRemotePeerState(remotePeer) }}
          </div>
          <div class="device_item_user">
            users: {{ remotePeer.streamRunning ? remotePeer.streamWatchers.length + 1 : 0 }}
          </div>
        </div>

        <!-- Button Row -->
        <div class="device_list__btn_row">
          <div
            *ngIf="!remotePeer.streamRunning"
            class="device_item_connect device_list__btn"
            [ngClass]="{ offline: !remotePeer.online }"
            (click)="startStreamAsOwner(remotePeer)"
          >
            {{ 'launcher.start_application' | translate }}
          </div>

          <div
            *ngIf="remotePeer.streamRunning"
            [ngClass]="{ offline: !remotePeer.online, 'device_item_connect device_list__btn': true }"
            (click)="startStreamAsSpectator(remotePeer)"
          >
            {{ 'launcher.join_as_spectator' | translate }}
          </div>
          <div
            *ngIf="userProfile?.canAdministrateStream"
            class="device_item__settings device_list__btn"
            (click)="openStreamSettingsModal(remotePeer.applicationId)"
          >
            <fa-icon [icon]="faWrench"></fa-icon>
          </div>
        </div>
      </li>
      <li *ngFor="let device of remoteDevices">
        <!-- Information Row -->
        <div class="device_list__info_row">
          <div class="device_item_name">VM: {{ device.alias ? device.alias : device.deviceName }}</div>
          <div class="device_item_is_online" [ngClass]="{ online: device.isOnline, offline: !device.isOnline }">
            {{ device.isOnline ? 'online' : 'offline' }}
          </div>
          <div class="device_item_user">-</div>
        </div>
        <!-- Button Row -->
        <div class="device_list__btn_row">
          <div class="device_item_connect device_list__btn" [ngClass]="{ offline: !device.isOnline }">
            <a [attr.href]="device.isOnline ? device.remoteUrl : null" rel="noreferrer">verbinden</a>
          </div>
          <div *ngIf="userProfile?.canAdministrateStream" class="device_item__settings device_list__btn">
            <fa-icon [icon]="faWrench"></fa-icon>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <p class="powered_by">
    Powered by
    <a href="https://www.visoric.com" target="_blank" rel="noreferrer"> Visoric GmbH</a>
  </p>
</ng-container>
