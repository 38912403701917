<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('dismiss')"></button>
</div>
<div class="modal-body">
  <p>{{ message }}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="activeModal.close('cancel')">
    {{ 'action.close' | translate }}
  </button>
  <button type="button" class="btn btn-success" (click)="activeModal.close('confirm')">
    {{ 'action.confirm' | translate }}
  </button>
</div>
