import { XrsApplication } from './xrs-application';
import { XRSUser } from './xrs-user';

export enum InputOfferState {
  HostHasInput = 0,
  PendingOffer = 1,
  AcceptedOffer = 2,
  InputAssigned = 3,
  DeclinedRevokedOffer = 4,
}

export interface InputOffer {
  /**
   * Unique id of the xrs user that offers input control
   */
  from: string;

  /**
   * Unique id of the xrs user receives the offer
   */
  to: string;

  /**
   * The application for which this input offer is valid
   */
  applicationId: string;

  state: InputOfferState;

  /**
   * If the offer was revoked once it becomes invalid and can no longer be processed
   */
  offerRevoked: boolean;

  created: number;
}

export type ResolvedInputOffer = InputOffer & {
  from: XRSUser;
  to: XRSUser;
  application: XrsApplication;
  offerAccepted: boolean;
  offerDeclined: boolean;
  offerRevoked: boolean;
  created: number;
};
