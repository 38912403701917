<div class="app_menu_bar">
  <div class="app_menu_bar__state">
    <div [ngClass]="{ connected: connected }"></div>
  </div>
  <div
    class="app_menu_bar__home clickable"
    (click)="home()"
    placement="bottom"
    [ngbTooltip]="'tooltip.exit_application' | translate"
  >
    <fa-icon [icon]="faPowerOff"></fa-icon>
  </div>
  <!-- <div
    class="app_menu_bar__refresh clickable"
    (click)="reloadConnection()"
    placement="bottom"
    [ngbTooltip]="'tooltip.reload_connection' | translate"
  >
    <fa-icon [icon]="faRefresh"></fa-icon>
  </div> -->
  <div *ngIf="!!application" class="app_menu_bar__info">
    <span>{{ application.name }}</span>
  </div>
  <div class="app_menu_bar__host">
    <div [ngSwitch]="userIsHost()">
      <div>{{ userProfile?.name }}</div>
      <div class="is_host" *ngSwitchCase="true">{{ 'app_menu_bar.host' | translate }}</div>
      <div class="is_vistor" *ngSwitchCase="false">{{ 'app_menu_bar.visitor' | translate }}</div>
    </div>
  </div>
  <div
    *ngIf="canOpenUserModal()"
    class="app_menu_bar__users clickable"
    (click)="openUserModal()"
    placement="bottom"
    [ngbTooltip]="'tooltip.show_all_users' | translate"
  >
    <ng-container>
      <fa-icon [icon]="faUser"></fa-icon>
      <span>{{ streamUsers.length }}</span>
    </ng-container>
    <ng-container *ngIf="canSeeWaitingUsers()">
      <fa-icon class="mx-2 text-warning" [icon]="faUserPlus"></fa-icon>
      <span class="text-warning">{{ waitingUsers.length || 0 }}</span>
    </ng-container>
  </div>
  <div
    *ngIf="canOpenControlModal()"
    class="app_menu_bar__controls clickable"
    (click)="openControlModal()"
    placement="bottom"
    [ngbTooltip]="getControlTooltip()"
  >
    <div [ngSwitch]="inputControlState">
      <div class="input_control__no_input" *ngSwitchCase="inputControlStates.NoControl">
        {{ 'app_menu_bar.item_no_control' | translate }}
      </div>
      <div class="input_control__pending_input" *ngSwitchCase="inputControlStates.PendingOffer">
        {{ 'app_menu_bar.pending_input_offer' | translate }}
      </div>
      <div class="input_control__input_assigned" *ngSwitchCase="inputControlStates.InputAssigned">
        {{ 'app_menu_bar.item_control' | translate }}
      </div>
    </div>
  </div>
  <div
    *ngIf="canShareLink()"
    class="app_menu_bar__share clickable"
    (click)="shareLink()"
    placement="bottom"
    [ngbTooltip]="'tooltip.invite_user' | translate"
  >
    <fa-icon [icon]="faArrowUpRightFromSquare"></fa-icon>
  </div>
  <div
    class="app_menu_bar__share clickable"
    (click)="fullscreen()"
    placement="bottom"
    [ngbTooltip]="'tooltip.fullscreen' | translate"
  >
    <fa-icon [icon]="faExpand"></fa-icon>
  </div>
  <div
    class="app_menu_bar__stats clickable"
    (click)="toogleStreamStats()"
    placement="bottom"
    [ngClass]="{ 'text-warning': isBadConnection() }"
    [ngbTooltip]="'tooltip.stats' | translate"
  >
    <fa-icon [icon]="faChartColumn"></fa-icon>
    <div class="stream-info" *ngIf="showStreamStats && connectionReport">
      <span [style.color]="getColor(connectionReport.bitrate_kbit_s)"
        >{{ connectionReport.bitrate_kbit_s }} kbit/s</span
      >
      <span>|</span>
      <span>{{ connectionReport.width }}x{{ connectionReport.height }}</span>
      <span>|</span>
      <span>{{ connectionReport.framerate }} FPS</span>
    </div>
  </div>
  <div class="version-info">v{{ version }}</div>
</div>
