import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { Subject } from 'rxjs';
import { webRTCConfiguration } from '../config/webrtc-config';
import Peer from '../libraries/peer';
import { VideoPlayer } from '../libraries/videoplayer';
import { Signaling } from '../libraries/signaling';
import { SubscriptionService } from './subscription.service';
import { UserProfile } from '../model/user-profile';
import { Session } from '../model/session';
import { SessionService } from './session.service';
import { NGXLogger } from 'ngx-logger';
import { ConnectionReportService } from './connection-report.service';
import { InputChannelGuardService } from './input-channel-guard.service';
import { XrsApplicationService } from './xrs-application.service';
import { XrsApplication } from '../model/xrs-application';

export interface RemoteDevice {}

@Injectable({
  providedIn: 'root',
})
export class RemoteStreamService {
  private connectionId: string;
  private videoPlayer: VideoPlayer;
  private peer: Peer | null;
  private inputChannel: RTCDataChannel;
  private user: UserProfile;
  private session: Session | undefined;

  private signalingServerUrl = '';
  private signaling: Signaling | undefined;

  onConnect = new Subject();
  onDisconnect = new Subject();
  onTrackEvent = new Subject();
  onTrackEnded = new Subject();
  onGotOffer = new Subject();
  onGotAnswer = new Subject();
  onGotChannel = new Subject();

  constructor(
    private subscriptionService: SubscriptionService,
    private sessionService: SessionService,
    private applicationService: XrsApplicationService,
    private inputChannelGuardService: InputChannelGuardService,
    private logger: NGXLogger,
    private connectionReportService: ConnectionReportService
  ) {}

  async start(videoPlayerRef: HTMLElement, application: XrsApplication, user: UserProfile): Promise<void> {
    this.logger.info(`[RemoteStreamService] Start stream for app id: ${application.id}`);
    this.logger.debug(`[RemoteStreamService] SignalingServerUrl: ${this.signalingServerUrl}`);

    const signalingServerUrl = this.applicationService.getSignalingServer(application);

    if (!signalingServerUrl) {
      this.logger.error(`[RemoteStreamService] No signaling server found for application ${application.id}`);
      return;
    }
    this.signalingServerUrl = signalingServerUrl;

    this.videoPlayer?.deletePlayer();
    this.videoPlayer = new VideoPlayer();
    this.videoPlayer?.createPlayer(videoPlayerRef);
    this.videoPlayer?.addEventListener('inputChannelSend', (event) => this.onInputChannelSend());
    this.videoPlayer?.addEventListener('inputChannelAcknowledged', (event) => this.onInputChannelAcknowledged());

    this.connectionId = uuidv4();
    this.user = user;
    this.session = await this.sessionService.getSessionOnce(application.id);

    this.signaling = new Signaling(this.logger);
    this.signaling.addEventListener('connect', (event) => this.handleIncomingConnect(event));
    this.signaling.addEventListener('disconnect', (event) => this.handleIncomingDisconnect(event));
    this.signaling.addEventListener('offer', (event) => this.handleIncomingOffer(event));
    this.signaling.addEventListener('answer', (event) => this.handleIncomingAnswer(event));
    this.signaling.addEventListener('candidate', (event) => this.handleIncomingCandidate(event));

    this.initInputChannelGuard();
    await this.connect();
  }

  stop(): void {
    this.logger.debug('[RemoteStreamService] stop remote stream servcice');

    this.subscriptionService.unsubscribe('rss');
    if (this.peer) {
      this.peer.close();
      this.peer = null;
    }
    this.inputChannelGuardService.stop();
    this.disconnect();
  }

  /**
   * When the input channel times out, we try to reset the connection.
   */
  private initInputChannelGuard(): void {
    this.inputChannelGuardService.onInputChannelTimeout.subscribe(() => {
      console.warn('[RemoteStreamService] Input channel timed out! Try to reconnect...');
      this.inputChannelGuardService.reset();
      this.disconnectInputChannel();
      setTimeout(() => {
        this.connectInputChannel();
      }, 1000);
    });
    this.inputChannelGuardService.start();
  }

  private async connect(): Promise<void> {
    this.logger.debug('[RemoteStreamService] connect signaling server');

    await this.signaling?.start(this.signalingServerUrl);
    await this.signaling?.createConnection(this.connectionId);
  }

  private async disconnect(): Promise<void> {
    this.logger.debug('[RemoteStreamService] disconnect signaling server');

    await this.signaling?.deleteConnection(this.connectionId);
    await this.signaling?.stop();
    (this.signaling as any)?.removeAllListeners();
    this.signaling = undefined;
  }

  resizeVideo(): void {
    this.videoPlayer?.resizeVideo();
  }

  createDataChannel(label: string): RTCDataChannel | undefined {
    this.logger.debug('[RemoteStreamService] createDataChannel');
    return this.peer?.createDataChannel(this.connectionId, label);
  }

  addTrack(track: MediaStreamTrack) {
    this.logger.debug('[RemoteStreamService] addTrack');
    return this.peer?.addTrack(this.connectionId, track);
  }

  getTransceivers(): RTCRtpTransceiver[] | null {
    this.logger.debug('[RemoteStreamService] getTransceivers');
    return this.peer?.getTransceivers(this.connectionId) ?? null;
  }

  getVideoPlayer(): VideoPlayer {
    return this.videoPlayer;
  }

  private async handleIncomingConnect(e: any): Promise<void> {
    this.logger.debug('[RemoteStreamService] handleIncomingConnect');

    const data = e.detail;
    if (this.connectionId == data.connectionId) {
      this.preparePeerConnection(this.connectionId, data.polite);
      this.connectInputChannel();
      this.onConnect?.next(this.connectionId);
    }
  }

  connectInputChannel(overrideHost = false): void {
    /*
     * We use three different channels to encode which user has input.
     * Host: 'input_host' -> This channel has always priority over other channels and can disconnect channel 'input'
     * Visitors: 'input' -> When the host disconnected they can connect with channel 'input'
     * Others: 'data_[random]' a random name
     *
     * Somehow channels have to be connected in order for the stream to work. Have to figure that our eventually
     */
    let channelName = '';
    if (this.sessionService.userIsHost(this.session, this.user)) {
      channelName = 'input_host';
    } else if (overrideHost) {
      channelName = 'input';
    } else {
      channelName = 'data_' + uuidv4().slice(0, 8);
    }
    const channel = this.createDataChannel(channelName);
    if (channel && (this.user.canControlStream || overrideHost)) {
      this.inputChannel = channel;
      /* Maybe add here some logic to reconnect the channel if it is lost */
      this.inputChannel.addEventListener('open', () => {
        this.logger.debug('[RemoteStreamService] input channel open');
      });
      this.inputChannel.addEventListener('closing', () => {
        this.logger.debug('[RemoteStreamService] input channel closing');
      });
      this.videoPlayer?.setupInput(channel);
    }
  }

  disconnectInputChannel(): void {
    this.inputChannel?.close();
  }

  private onInputChannelSend(): void {
    this.inputChannelGuardService.updateSend();
  }

  private onInputChannelAcknowledged(): void {
    this.inputChannelGuardService.updateReceived();
  }

  private async handleIncomingDisconnect(e: any): Promise<void> {
    this.logger.debug('[RemoteStreamService] handleIncomingDisconnect');

    const data = e.detail;
    if (this.connectionId == data.connectionId) {
      if (this.peer) {
        this.peer.close();
        this.peer = null;
      }
      this.disconnectInputChannel();
      this.onDisconnect.next(this.connectionId);
    }
  }

  private async handleIncomingOffer(e: any): Promise<void> {
    this.logger.debug('[RemoteStreamService] handleIncomingOffer');

    const offer = e.detail;
    if (!this.peer) {
      this.preparePeerConnection(offer.connectionId, offer.polite);
    }

    const desc = new RTCSessionDescription({ sdp: offer.sdp, type: 'offer' });
    try {
      await this.peer?.onGotDescription(offer.connectionId, desc);
    } catch (error) {
      this.logger.warn(
        `Error happen on GotDescription that description.\n Message: ${error}\n RTCSdpType:${desc.type}\n sdp:${desc.sdp}`
      );
      return;
    }
  }

  private async handleIncomingAnswer(e: any): Promise<void> {
    this.logger.debug('[RemoteStreamService] handleIncomingAnswer');

    const answer = e.detail;
    const desc = new RTCSessionDescription({ sdp: answer.sdp, type: 'answer' });
    if (this.peer) {
      try {
        await this.peer.onGotDescription(answer.connectionId, desc);
      } catch (error) {
        this.logger.error(
          `Error happen on GotDescription that description.\n Message: ${error}\n RTCSdpType:${desc.type}\n sdp:${desc.sdp}`
        );
        return;
      }
    }
  }

  private async handleIncomingCandidate(e: any): Promise<void> {
    this.logger.debug('[RemoteStreamService] handleIncomingCandidate');

    const candidate = e.detail;
    const iceCandidate = new RTCIceCandidate({
      candidate: candidate.candidate,
      sdpMid: candidate.sdpMid,
      sdpMLineIndex: candidate.sdpMLineIndex,
    });
    if (this.peer) {
      await this.peer.onGotCandidate(candidate.connectionId, iceCandidate);
    }
  }

  private preparePeerConnection(connectionId: string, polite: boolean): Peer {
    if (this.peer) {
      this.logger.debug('[RenderStreamService] Close current PeerConnection');
      this.peer.close();
      this.peer = null;
    }

    // Create peerConnection with proxy server and set up handlers
    this.peer = new Peer(connectionId, polite, webRTCConfiguration, 5000, this.logger);
    this.peer.addEventListener('disconnect', () => {
      this.connectionReportService.stop();
      this.logger.debug(`[RenderStreamService] peer disconnect`);
      this.onDisconnect.next(`Receive disconnect message from peer. connectionId:${connectionId}`);
    });

    this.peer.addEventListener('trackevent', (e: any) => {
      this.logger.debug(`[RenderStreamService] peer trackevent`, e.detail);
      const data = e.detail;

      /*
       * Sometimes the stream gets reported as "ended" even before we started it. In this case
       * we want to reset the current connection attempt and try again
       */
      const readyState = data?.track?.readyState;
      if (readyState !== 'live') {
        this.onTrackEnded?.next(undefined);
      } else {
        this.videoPlayer?.addTrack(data.track);
      }
    });

    this.peer.addEventListener('adddatachannel', (e: any) => {
      this.logger.debug(`[RenderStreamService] peer adddatachannel`);
      const data = e.detail;
      this.onGotChannel.next(data);
    });

    this.peer.addEventListener('ongotoffer', (e: any) => {
      this.logger.debug(`[RenderStreamService] peer ongotoffer`);
      const id = e.detail.connectionId;
      this.onGotOffer.next(id);
    });

    this.peer.addEventListener('ongotanswer', (e: any) => {
      this.logger.debug(`[RenderStreamService] peer ongotanswer`);
      const id = e.detail.connectionId;
      this.onGotAnswer.next(id);
    });

    this.peer.addEventListener('sendoffer', (e: any) => {
      this.logger.debug(`[RenderStreamService] peer sendoffer`);
      const offer = e.detail;
      this.signaling?.sendOffer(offer.connectionId, offer.sdp);
    });

    this.peer.addEventListener('sendanswer', (e: any) => {
      this.logger.debug(`[RenderStreamService] peer sendanswer`);
      const answer = e.detail;
      this.signaling?.sendAnswer(answer.connectionId, answer.sdp);
    });

    this.peer.addEventListener('sendcandidate', (e: any) => {
      this.logger.debug(`[RenderStreamService] peer sendcandidate`);
      const candidate = e.detail;
      this.signaling?.sendCandidate(
        candidate.connectionId,
        candidate.candidate,
        candidate.sdpMid,
        candidate.sdpMLineIndex
      );
    });
    this.connectionReportService.start(connectionId, this.peer);
    return this.peer;
  }
}
