import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { interval, map, Observable, Subscription } from 'rxjs';
import { Session } from '../model/session';
import { UserProfile } from '../model/user-profile';
import { XRSUser } from '../model/xrs-user';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private sessions: Session[] = [];
  private userUpdateSubscription: Subscription;
  private sessionUpdateSubscription: Subscription;

  constructor(private socket: Socket, private authService: AuthService) {
    this.socket.fromEvent<Session[]>('sessions').subscribe((sessions: Session[]) => {
      this.sessions = sessions;
    });

    interval(1000).subscribe(() => {
      this.socket.emit('getSessions');
    });
  }

  async getSessionOnce(applicationId: string): Promise<Session | undefined> {
    this.socket.emit('getSessions');
    const sessions = await this.socket.fromOneTimeEvent<Session[]>('sessions');
    return sessions.find((session) => session.applicationId === applicationId);
  }

  getSessions(): Observable<Session[]> {
    return this.socket.fromEvent<Session[]>('sessions');
  }

  getSession(applicationId: string): Observable<Session | undefined> {
    return this.socket
      .fromEvent<Session[]>('sessions')
      .pipe(map((sessions) => sessions.find((session) => session.applicationId === applicationId)));
  }

  getSessionUsersOnce(applicationId: string): XRSUser[] {
    const session = this.sessions.find((session) => session.applicationId === applicationId);
    if (session) {
      return session.visitors ?? [];
    } else {
      return [];
    }
  }

  createSession(applicationId: string, hostUserProfile: UserProfile): Session | undefined {
    if (!hostUserProfile || hostUserProfile.isAnonymous) {
      return;
    }

    const host: XRSUser = {
      id: hostUserProfile.id,
      browserId: this.authService.uniqueBrowserId,
      name: hostUserProfile.name,
      isAnonymous: hostUserProfile.isAnonymous,
      lastUpdate: 0,
    };

    //TODO: This has to be extended later.
    //TODO: For now disallow visitors when the user uses a direct connection to an application
    const allowVisitors = !hostUserProfile.directApplicationConnection;

    const session: Session = {
      applicationId,
      host,
      waitingRoom: [],
      visitors: [],
      allowVisitors,
      lastUpdate: Date.now(),
    };
    this.socket.emit('addSession', session);

    return session;
  }

  deleteSession(session: Session | undefined): void {
    if (!session) {
      return;
    }
    this.deleteSessionById(session.applicationId);
  }

  deleteSessionById(applicationId: string): void {
    if (!applicationId) {
      return;
    }
    this.socket.emit('deleteSession', applicationId);
  }

  joinSession(session: Session | undefined, userProfile: UserProfile): void {
    if (!session || !userProfile) {
      return;
    }

    const user: XRSUser = {
      id: userProfile.id,
      browserId: this.authService.uniqueBrowserId,
      name: userProfile.name,
      isAnonymous: userProfile.isAnonymous,
      lastUpdate: 0,
    };
    this.socket.emit('joinSession', session, user);
  }

  leaveSession(session: Session | undefined, userProfile: UserProfile): void {
    if (!session || !userProfile) {
      return;
    }

    this.socket.emit('leaveSession', session, userProfile.id);
  }

  acceptUserToSession(session: Session | undefined, user: XRSUser): void {
    if (!session || !user) {
      return;
    }

    this.socket.emit('acceptUserToSession', session, user.id);
  }

  removeUserFromSession(session: Session | undefined, user: XRSUser): void {
    if (!session || !user) {
      return;
    }
    this.socket.emit('leaveSession', session, user.id);
  }

  startSessionUpdate(session: Session | undefined): void {
    if (!session) {
      return;
    }
    this.stopSessionUpdate();

    this.sessionUpdateSubscription = interval(1000).subscribe(() => {
      this.socket.emit('updateSession', session);
    });
  }

  stopSessionUpdate(): void {
    this.sessionUpdateSubscription?.unsubscribe();
  }

  startUserUpdate(session: Session | undefined, userProfile: UserProfile): void {
    if (!session || !userProfile) {
      return;
    }
    this.userUpdateSubscription = interval(1000).subscribe(() => {
      this.socket.emit('updateSessionUser', session, userProfile.id);
    });
  }

  stopUserUpdate(): void {
    this.userUpdateSubscription?.unsubscribe();
  }

  streamHasHost(session: Session | undefined): boolean {
    return !!session?.host;
  }

  userIsHost(session: Session | undefined, userProfile: UserProfile | undefined): boolean {
    if (!session || !userProfile) {
      return false;
    }

    return session?.host.id === userProfile.id;
  }

  otherUserIsHost(session: Session | undefined, user: XRSUser | undefined): boolean {
    if (!session || !user) {
      return false;
    }

    return session?.host.id === user.id;
  }

  userIsInSession(session: Session | undefined, userProfile: UserProfile | undefined): boolean {
    if (!session || !userProfile) {
      return false;
    }

    const userIsHost = session?.host.id === userProfile?.id;
    const userIsVisitor = session?.visitors?.some((visitor) => visitor.id === userProfile.id) ?? false;
    const userIsInWaitingRoom = session?.waitingRoom?.some((visitor) => visitor.id === userProfile.id) ?? false;
    return userIsHost || userIsVisitor || userIsInWaitingRoom;
  }

  getSessionUser(session: Session | undefined, userProfile: UserProfile | undefined): XRSUser | undefined {
    if (!session || !userProfile) {
      return undefined;
    }

    if (session.host.id === userProfile.id) {
      return session.host;
    }

    const user = session.visitors?.find((visitor) => visitor.id === userProfile.id);
    if (user) {
      return user;
    }

    const userInWaitingRoom = session.waitingRoom?.find((visitor) => visitor.id === userProfile.id);
    if (userInWaitingRoom) {
      return userInWaitingRoom;
    }

    return undefined;
  }
}
