import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { DevicesPanelComponent } from './components/devices-panel/devices-panel.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthGuardService } from './guards/auth-guard';
import { LoadingIndicatorComponent } from './components/shared/loading-indicator/loading-indicator.component';
import { CustomRoutingComponent } from './components/custom-routing/custom-routing.component';
import { StreamPlayerComponent } from './components/stream-player/stream-player.component';
import { RemoteStreamComponent } from './components/remote-stream/remote-stream.component';
import { environment } from '../environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SocketIoModule } from 'ngx-socket-io';
import { AppMenuBarModule } from './components/app-menu-bar/app-menu-bar.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { WaitingRoomComponent } from './components/waiting-room/waiting-room.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoggerModule } from 'ngx-logger';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DevicesPanelComponent,
    LoadingIndicatorComponent,
    CustomRoutingComponent,
    StreamPlayerComponent,
    RemoteStreamComponent,
    WaitingRoomComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    AppMenuBarModule,
    FontAwesomeModule,
    SocketIoModule.forRoot({ url: environment.backend.url, options: {} }),
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true,
        allowedUrls: ['https://auth.xrstager.online'],
      },
    }),
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    LoggerModule.forRoot({
      level: environment.logLevel,
    }),
  ],
  providers: [AuthGuardService],
  bootstrap: [AppComponent],
})
export class AppModule {}
