import { Injectable } from '@angular/core';
import { interval, Subject, SubjectLike, Subscription } from 'rxjs';
import { XRSUser } from '../model/xrs-user';
import { v4 as uuidv4 } from 'uuid';
import { Socket } from 'ngx-socket-io';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class XrsUserService {
  xrsUsers: Subject<XRSUser[]> = new Subject();
  xrsUsersWaitingRoom: Subject<XRSUser[]> = new Subject();

  private lastUsers: XRSUser[] = [];
  private pollIntervall: Subscription;
  private notifiedOnce = false;

  constructor(private socket: Socket, private authService: AuthService) {
    this.socket.fromEvent<XRSUser[]>('users').subscribe((users: XRSUser[]) => {
      if (!this.notifiedOnce || this.usersChanged(users)) {
        this.lastUsers = users;
        this.xrsUsers?.next(users);
        this.notifiedOnce = true;
      }
    });

    this.pollIntervall = interval(1000).subscribe(() => {
      this.socket.emit('getUsers');
    });

    setTimeout(() => {
      this.xrsUsers?.next([]);
    }, 1000);
  }

  addUser(name: string, applicationId: string, isAnonymous: boolean = true): XRSUser {
    const lastUpdate = Date.now();
    const id = uuidv4();

    const user: XRSUser = {
      id,
      browserId: this.authService.uniqueBrowserId,
      name,
      isAnonymous,
      lastUpdate,
    };

    this.socket.emit('addUser', user);
    return user;
  }

  updateUser(id: string): void {
    this.socket.emit('keepUserAlive', id);
  }

  deleteUser(id: string): void {
    this.socket.emit('deleteUser', id);
  }

  getUserById(id: string): XRSUser | undefined {
    return this.lastUsers.find((user) => user.id === id);
  }

  /**
   * Store the last used user id in local storage. This is done the correctly delete
   * them from the user database when the page is disconnected in such a way that cannot
   * be detected
   * @param id
   */
  storePreviousUser(id: string): void {
    localStorage.setItem('lastUserId', id);
  }

  /**
   * Reset the last used user id in the user database to prevent this user from
   * appearing multiple times in the database.
   */
  resetPreviousUser(): void {
    const lastUserId = localStorage.getItem('lastUserId');
    if (lastUserId) {
      this.deleteUser(lastUserId);
    }
  }

  private usersChanged(newUsers: XRSUser[]) {
    const n_ids = newUsers.map((user) => user.id).sort();
    const o_ids = this.lastUsers.map((user) => user.id).sort();

    if (n_ids.length != o_ids.length) {
      return true;
    }

    for (let i = 0; i < n_ids.length; i++) {
      if (n_ids[i] !== o_ids[i]) {
        return true;
      }
    }

    return false;
  }
}
