import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { firstValueFrom } from 'rxjs';
import { Session } from 'src/app/model/session';
import { UserProfile } from 'src/app/model/user-profile';
import { XrsApplication } from 'src/app/model/xrs-application';
import { AuthService } from 'src/app/services/auth.service';
import { SessionService } from 'src/app/services/session.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { XrsApplicationService } from 'src/app/services/xrs-application.service';

enum WaitingRoomState {
  WaitingForSession,
  RegisterAtSession,
  WaitingForHost,
  JoinSession,
}

@Component({
  selector: 'app-waiting-room',
  templateUrl: './waiting-room.component.html',
  styleUrls: ['./waiting-room.component.scss'],
})
export class WaitingRoomComponent implements OnInit, OnDestroy {
  @ViewChild('username') usernameRef: ElementRef;

  application: XrsApplication;
  session: Session | undefined;
  user: UserProfile | undefined;
  userName: String = '';

  waitingRoomState = WaitingRoomState;
  state = WaitingRoomState.WaitingForSession;

  get title(): string {
    if (!this.application) {
      return '';
    }
    const applicationName = this.application?.name || '';
    const sessionStatus = !!this.session ? 'online' : 'not started yet';
    return `Waiting Room: ${applicationName} (${sessionStatus})`;
  }

  description = 'Waiting for a host to start the session.';

  constructor(
    private applicationService: XrsApplicationService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService,
    private subscriptionService: SubscriptionService,
    private logger: NGXLogger
  ) {}

  async ngOnInit(): Promise<void> {
    const app = await this.applicationService.getApplicationFromUrl();
    if (!app) {
      this.logger.error(`[WaitingRoomComponent] Application not found.`);
      this.handleDisconnect();
      return;
    }

    const session = await this.sessionService.getSessionOnce(app.id);
    if (!session) {
      this.logger.error(`[WaitingRoomComponent] Session not found. ${app.id}`);
      this.handleDisconnect();
      return;
    }

    const userWaitingRoomId = (await firstValueFrom(this.activatedRoute.queryParamMap)).get('userId');
    const storedUserProfile = await this.authService.getUserProfile(true);

    if (userWaitingRoomId !== storedUserProfile?.id) {
      this.logger.error(
        `[WaitingRoomComponent] userId in link does not match stored user id. userId: ${userWaitingRoomId}, storedUserId: ${storedUserProfile?.id}`
      );
      this.handleDisconnect();
    }

    this.application = app;
    this.user = storedUserProfile;
    this.userName = this.user?.name || '';

    const sessionSub = this.sessionService.getSession(this.application.id).subscribe((session) => {
      this.session = session;

      switch (this.state) {
        case WaitingRoomState.WaitingForSession:
          if (session) {
            this.changeStateRegisterAtSession();
          }
          break;
        case WaitingRoomState.RegisterAtSession:
          if (session && this.userIsWaiting()) {
            this.changeStateWaitingForHost();
          }
          break;
        case WaitingRoomState.WaitingForHost:
          if (session && this.userIsVisitor()) {
            this.changeStateJoinSession();
          } else if (session && !this.userIsWaiting() && !this.userIsVisitor()) {
            this.description = 'The host has declined your request. Leaving waiting room...';
            this.handleDisconnect();
          }
          break;
      }

      if (!session) {
        // Waiting for the stream to start
      } else if (session && this.userIsWaiting()) {
        // Waiting for the host to accept the user to the session
        this.state = WaitingRoomState.WaitingForHost;
        this.description = 'Wait for the host to confirm your attendance.';
      } else if (session && this.userIsVisitor()) {
        // Session has started and host accepted user into session
        this.state = WaitingRoomState.JoinSession;
        this.description = 'Connecting to session...';
      }
    });
    this.subscriptionService.add('waitingroom', sessionSub);
  }

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribe('waitingroom');
  }

  changeStateRegisterAtSession(): void {
    if (this.session && this.user) {
      this.state = WaitingRoomState.RegisterAtSession;
      this.description = 'Join session. The host has to accept your request';
    }
  }

  changeStateWaitingForHost(): void {
    if (this.session && this.user) {
      this.state = WaitingRoomState.WaitingForHost;
      this.description = 'Wait for the host to confirm your attendance.';
    }
  }

  changeStateJoinSession(): void {
    if (this.session && this.user) {
      this.state = WaitingRoomState.JoinSession;
      this.description = 'Connecting to session...';

      setTimeout(async () => {
        await this.router.navigate([''], {
          queryParams: { page: 'stream' },
          queryParamsHandling: 'merge',
        });
      }, 2000);
    }
  }

  joinSession(): void {
    this.changeUserName();

    if (this.session && this.user) {
      this.sessionService.joinSession(this.session, this.user);
      this.description = 'Waiting for the host to accept your request...';
      this.state = WaitingRoomState.WaitingForHost;
    }
  }

  cancel(): void {
    if (this.session && this.user) {
      this.sessionService.leaveSession(this.session, this.user);
      this.description = 'Leaving waiting room...';
      this.handleDisconnect();
    }
  }

  userIsWaiting(): boolean {
    return !!this.session && !!this.session.waitingRoom?.find((user) => user.id === this.user?.id);
  }

  userIsVisitor(): boolean {
    return !!this.session && !!this.session.visitors?.find((user) => user.id === this.user?.id);
  }

  async handleDisconnect(): Promise<void> {
    setTimeout(async () => {
      await this.router.navigate([''], {
        queryParams: { page: 'devices' },
      });
    }, 4000);
  }

  canChangeUsername(): boolean {
    return !!this.user && this.user?.isAnonymous;
  }

  changeUserName(): void {
    if (!this.user) {
      return;
    }

    const username = this.usernameRef.nativeElement.value;
    if (!!username && username.length > 0) {
      this.user.name = username;
      this.authService.updateAnonymouseUserProfile(this.user);
    }
  }
}
