<div id="player-background"></div>
<div *ngIf="!showSoftDisconnectMessage && !connectionInitialized" id="loading-screen">
  <div class="blurred-background"></div>
  <div class="loading-screen__content" [ngClass]="{ fade: !permissionsChecked }">
    <span>{{ loadingScreenText }}</span>
    <app-loading-indicator></app-loading-indicator>
  </div>
</div>
<div *ngIf="showSoftDisconnectMessage" id="disconnect-message">
  <div class="blurred-background"></div>
  <div class="disconnect-message__content">
    <span>{{ disconnectText }}</span>
  </div>
</div>
<app-waiting-room *ngIf=""></app-waiting-room>
<app-app-menu-bar (onReloadConnection)="tryReconnect()" (onHome)="onHome()" [connected]="connected"></app-app-menu-bar>
<div id="player" #player></div>
<div id="still-image" class="text-debugger" *ngIf="stillImageDataURI">
  <img [src]="stillImageDataURI" #stillImage />
</div>
