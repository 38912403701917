import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { XrsUserService } from './xrs-user.service';

@Injectable({
  providedIn: 'root',
})
export class StreamAdministrationService {
  constructor(private sessionService: SessionService) {}

  /**
   * Disconnect all users to make the stream available again.
   */
  async resetStream(applicationId: string): Promise<void> {
    this.sessionService.deleteSessionById(applicationId);
  }
}
