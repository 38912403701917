<div class="modal-header">
  <h4 class="modal-title">{{ 'modal.controls.title' | translate }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <!-- Stream visitor can only decline control if they have it  -->
  <div class="d-flex flex-direction-row justify-content-center">
    <button
      *ngIf="!userIsHost() && visitorHasInput()"
      type="button"
      class="btn btn-danger btn-block"
      (click)="declineInput()"
    >
      {{ 'action.release_control' | translate }}
    </button>
  </div>

  <!-- Stream Owner can give and revoke control -->
  <ul *ngIf="userIsHost()" class="controls_list">
    <li *ngFor="let user of filteredSessionUsers">
      <div class="controls_list__info">
        <div class="controls_list__name">{{ user.name }}</div>
      </div>
      <div class="controls_list__action">
        <button
          type="button"
          class="btn btn-outline-dark btn-sm"
          *ngIf="canOfferInput(user)"
          (click)="offerInput(user)"
        >
          {{ 'action.give_input' | translate }}
        </button>
        <button type="button" class="btn btn-danger btn-sm" *ngIf="canRevokeInput(user)" (click)="revokeInput()">
          {{ 'action.remove_input' | translate }}
        </button>
      </div>
    </li>
  </ul>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">
    {{ 'action.close' | translate }}
  </button>
</div>
