import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, Observable } from 'rxjs';
import { XrsApplication } from 'src/app/model/xrs-application';
import { XRSUser } from 'src/app/model/xrs-user';
import { InputOfferService } from 'src/app/services/input-offer.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { XrsApplicationService } from 'src/app/services/xrs-application.service';
import { ControlsModalComponent } from './modals/controls/controls-modal.component';
import { VisitorsModalComponent } from './modals/visitors/visitors-modal.component';
import packageInfo from '../../../../package.json';
import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { faChartColumn } from '@fortawesome/free-solid-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { SessionService } from 'src/app/services/session.service';
import { UserProfile } from 'src/app/model/user-profile';
import { Session } from 'src/app/model/session';
import { AuthService } from 'src/app/services/auth.service';
import { InviteComponent } from './modals/invite/invite.component';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { ConnectionReport, ConnectionReportService } from 'src/app/services/connection-report.service';

export enum InputControlState {
  NoControl,
  PendingOffer,
  InputAssigned,
}

@Component({
  selector: 'app-app-menu-bar',
  templateUrl: './app-menu-bar.component.html',
  styleUrls: ['./app-menu-bar.component.scss'],
})
export class AppMenuBarComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('offerInputTemplate') offerInputTemplate: ElementRef;

  @Output() onReloadConnection = new EventEmitter();
  @Output() onHome = new EventEmitter();

  @Input() connected: boolean;

  session: Session;
  userProfile: UserProfile | undefined;
  application: XrsApplication | undefined;
  streamUsers: XRSUser[];
  waitingUsers: XRSUser[] = [];

  inputControlStates = InputControlState;
  inputControlState = InputControlState.NoControl;
  userWithInput: XRSUser | undefined;

  showStreamStats = false;
  connectionReport: ConnectionReport;

  version = packageInfo.version;

  faHome = faHome;
  faRefresh = faRefresh;
  faUser = faUser;
  faExpand = faExpand;
  faUserPlus = faUserPlus;
  faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  faChartColumn = faChartColumn;
  faPowerOff = faPowerOff;

  constructor(
    private applicationService: XrsApplicationService,
    private inputOfferService: InputOfferService,
    private subscriptionService: SubscriptionService,
    private modalService: NgbModal,
    private authService: AuthService,
    private sessionService: SessionService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private connectionReportService: ConnectionReportService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.subscriptionService.add(
      'amb',
      this.inputOfferService.onOfferReceived.subscribe(() => this.offerReceived())
    );
    this.subscriptionService.add(
      'amb',
      this.inputOfferService.onOfferAccepted.subscribe(() => this.offerAccepted())
    );
    this.subscriptionService.add(
      'amb',
      this.inputOfferService.onOfferDeclined.subscribe(() => this.offerDeclined())
    );
    this.subscriptionService.add(
      'amb',
      this.inputOfferService.onOfferRevoked.subscribe(() => this.offerRevoked())
    );
    this.subscriptionService.add(
      'amb',
      this.inputOfferService.onInputAssigned.subscribe(() => this.inputAssigned())
    );
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.userProfile = await this.authService.getUserProfile(true);
    if (this.userIsHost()) {
      this.inputControlState = InputControlState.InputAssigned;
    }
    this.changeDetectorRef.detectChanges();
  }

  async ngOnInit(): Promise<void> {
    this.application = await this.applicationService.getApplicationFromUrl();

    if (!this.application) {
      return;
    }

    this.subscriptionService.add(
      'amb',
      this.sessionService
        .getSession(this.application.id)
        .pipe(map((session) => this.readSessionInfo(session)))
        .subscribe()
    );

    this.subscriptionService.add(
      'amb',
      this.connectionReportService.reportUpdate.subscribe((report) => {
        this.connectionReport = report;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribe('amb');
  }

  readSessionInfo(session: Session | undefined): void {
    if (session) {
      this.session = session;

      if (this.userIsHost() && this.waitingUsers.length === 0 && session.waitingRoom?.length > 0) {
        this.toastService.defaultToast('There are users waiting to join the session.');
      }
      this.streamUsers = session.visitors ?? [];
      this.waitingUsers = session.waitingRoom ?? [];
    } else {
      this.waitingUsers = [];
    }
  }

  offerInput(user: XRSUser): void {
    this.inputOfferService.offerInput(user.id);
  }

  revokeInput(): void {
    this.inputOfferService.revokeInputOffer();
  }

  offerRevoked(): void {
    const inputOffer = this.inputOfferService.getInputOffer();
    this.userWithInput = undefined;

    if (inputOffer?.from === this.userProfile?.id) {
      this.inputControlState = InputControlState.InputAssigned;
    } else if (inputOffer?.to === this.userProfile?.id) {
      this.inputControlState = InputControlState.NoControl;
    }
  }
  offerDeclined(): void {
    const inputOffer = this.inputOfferService.getInputOffer();
    this.userWithInput = undefined;

    if (inputOffer?.from === this.userProfile?.id) {
      this.inputControlState = InputControlState.InputAssigned;
    } else if (inputOffer?.to === this.userProfile?.id) {
      this.inputControlState = InputControlState.NoControl;
    }
  }
  offerAccepted(): void {
    const inputOffer = this.inputOfferService.getInputOffer();
    this.userWithInput = this.session?.visitors?.find((user) => user.id === inputOffer?.to);

    if (inputOffer?.from === this.userProfile?.id) {
      this.inputControlState = InputControlState.NoControl;
    } else if (inputOffer?.to === this.userProfile?.id) {
      this.inputControlState = InputControlState.PendingOffer;
    }
  }
  offerReceived(): void {
    const inputOffer = this.inputOfferService.getInputOffer();
    this.userWithInput = undefined;

    if (inputOffer?.from === this.userProfile?.id) {
      this.inputControlState = InputControlState.NoControl;
    } else if (inputOffer?.to === this.userProfile?.id) {
      this.inputControlState = InputControlState.PendingOffer;
    }
  }
  inputAssigned(): void {
    const inputOffer = this.inputOfferService.getInputOffer();
    this.userWithInput = this.session?.visitors?.find((user) => user.id === inputOffer?.to);

    if (inputOffer?.from === this.userProfile?.id) {
      this.inputControlState = InputControlState.NoControl;
    } else if (inputOffer?.to === this.userProfile?.id) {
      this.inputControlState = InputControlState.InputAssigned;
    }
  }

  getControlTooltip(): string {
    if (this.userWithInput && this.userWithInput.id === this.userProfile?.id) {
      return this.translateService.instant('app_menu_bar.label_you_control');
    } else if (this.userWithInput) {
      return `${this.userWithInput.name} ${this.translateService.instant('app_menu_bar.label_other_control')}`;
    } else if (!this.userWithInput && this.userIsHost()) {
      return this.translateService.instant('app_menu_bar.label_you_control');
    } else {
      return this.translateService.instant('app_menu_bar.label_you_control');
    }
  }

  async home(): Promise<void> {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.message = this.translateService.instant('app_menu_bar.confirm_exit');
    modalRef.result.then(
      async (result) => {
        switch (result) {
          case 'confirm':
            modalRef.close();
            this.onHome?.emit();
            break;
          case 'close': {
            modalRef.close();
          }
        }
      },
      (reason) => {
        modalRef.close();
      }
    );
  }

  reloadConnection(): void {
    this.onReloadConnection?.emit();
  }

  canOpenUserModal(): boolean {
    const isDirectApplicationConnection = !!this.userProfile?.directApplicationConnection;
    return this.userIsHost() && !isDirectApplicationConnection;
  }

  async openUserModal(): Promise<void> {
    const modalStreamUsers = this.sessionService.getSessionUsersOnce(this.application?.id || '');
    const modalRef = this.modalService.open(VisitorsModalComponent, { size: 'lg' });
    modalRef.componentInstance.isStreamOwner = this.userIsHost();
    modalRef.componentInstance.streamUsers = modalStreamUsers;
  }

  canOpenControlModal(): boolean {
    const isDirectApplicationConnection = !!this.userProfile?.directApplicationConnection;
    return !!this.userProfile && !isDirectApplicationConnection;
  }

  async openControlModal(): Promise<void> {
    const modalStreamUsers = this.sessionService.getSessionUsersOnce(this.application?.id || '');
    if (modalStreamUsers?.length < 1) {
      return;
    }

    const modalRef = this.modalService.open(ControlsModalComponent);
    modalRef.componentInstance.user = this.userProfile;
    modalRef.componentInstance.streamUsers = modalStreamUsers;
  }

  canShareLink(): boolean {
    const isDirectApplicationConnection = !!this.userProfile?.directApplicationConnection;
    return this.userIsHost() && !isDirectApplicationConnection;
  }

  shareLink(): void {
    const modalRef = this.modalService.open(InviteComponent);
  }

  fullscreen(): void {}

  userIsHost(): boolean {
    return this.sessionService.userIsHost(this.session, this.userProfile);
  }

  canSeeWaitingUsers(): boolean {
    return this.userIsHost() && this.waitingUsers.length > 0;
  }

  getStreamStatsTooltip(): string {
    if (!this.connectionReport) {
      return this.translateService.instant('app_menu_bar.not_connected');
    } else {
      return `${this.connectionReport.bitrate_kbit_s} kbit/s | ${this.connectionReport.width}x${this.connectionReport.height} | ${this.connectionReport.framerate} fps`;
    }
  }

  toogleStreamStats(): void {
    this.showStreamStats = !this.showStreamStats;
  }

  isBadConnection(): boolean {
    return this.connectionReport?.bitrate_kbit_s < 200;
  }

  getColor(bitrate_kbit_s: number): string {
    if (bitrate_kbit_s < 200) {
      return '#c3423f';
    } else if (bitrate_kbit_s >= 200 && bitrate_kbit_s <= 500) {
      return 'fde74c';
    } else {
      return '#9bc53d';
    }
  }
}
