import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserProfile } from 'src/app/model/user-profile';
import { XrsApplication } from 'src/app/model/xrs-application';
import { XRSUser } from 'src/app/model/xrs-user';
import { SessionService } from 'src/app/services/session.service';
import { StreamAdministrationService } from 'src/app/services/stream-administration.service';

@Component({
  selector: 'app-stream-admin-modal',
  templateUrl: './stream-admin-modal.component.html',
  styleUrls: ['./stream-admin-modal.component.scss'],
})
export class StreamAdministrationModalComponent implements OnInit {
  @Input() userProfile: UserProfile;
  @Input() application: XrsApplication;

  sessionUsers: XRSUser[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private streamAdministrationService: StreamAdministrationService,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.sessionService.getSessionOnce(this.application.id).then((session) => {
      if (session) {
        this.sessionUsers = [session.host, ...(session.visitors ?? [])];
      }
    });
  }

  resetStream(): void {
    if (this.userProfile?.canAdministrateStream) {
      this.streamAdministrationService.resetStream(this.application.id);
      this.activeModal.close();
    }
  }
}
